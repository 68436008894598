import { lazy as loadable } from 'react';
import env from '@beam-australia/react-env';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const lazyRouter = (feature: string, page: string) =>
  env('NODE_ENV') === 'production'
    ? loadable(() =>
        import(
          /* webpackMode: "lazy" */
          `../features/${feature}/pages/${page}`
        ),
      )
    : loadable(() =>
        import(
          /* webpackMode: "eager" */
          `../features/${feature}/pages/${page}`
        ),
      );
