import * as React from 'react';
import env from '@beam-australia/react-env';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Col, Row, Divider, Form, Input, Checkbox, Tooltip, Icon } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useRequest } from 'estafette';
import { useDocumentTitle } from 'hooks';
import { getRoute } from 'libs/router';
import { offers, orders } from 'libs/http/api';
import { parseObject, parseQuery, Query } from 'libs/http/url';
import { Offer } from 'libs/http/api/offers';
import { Field as FieldInterface } from 'libs/http/api/management/field';
import { has, validate } from 'libs/object';
import { Addresses, Pricing } from 'apps/results-app/types';
import { getAddressFromObject } from 'apps/results-app/libs/string';
import { formatMoney } from 'apps/results-app/libs/number';
import { cleanEmptyStrings } from 'apps/results-app/libs/object';
import { Tag } from 'apps/results-app/atoms';
import { Seeking } from 'apps/results-app/organisms';
import { Loader, Animated, Button, Card, CardHeader, CardContent, DescriptionListHeader } from 'ui/atoms';
import { Layout } from 'ui/templates';

import { placeholder } from '../../placeholder';
import { Field } from '../../atoms';

import './_CheckoutPage.scss';

const CheckoutPage: React.FC = () => {
  const setTitle = useDocumentTitle();
  const history = useHistory();
  const location = useLocation();
  const { offer, ...params } = useParams();
  const { request, data: $data, loading } = useRequest<Offer>({ data: {} });
  const { request: requestOrder, errors, loading: loadingOrder } = useRequest();
  const [showSearch, setShowSearch] = React.useState<boolean>(false);
  const [query, setQuery] = React.useState<Query>({});
  const [addressChanged, setAddressChanged] = React.useState<boolean>(false);
  const [address, setAddress] = React.useState<{ [key: string]: string | number }>({});
  const [fields, setFields] = React.useState<{ [key: string]: string }>({});
  const [termsChecked, setTermsChecked] = React.useState<boolean>(false);
  const [policyChecked, setPolicyChecked] = React.useState<boolean>(false);
  const [apartament, setApartament] = React.useState<string>('');

  const data = loading ? placeholder : $data;

  React.useEffect(() => {
    const onFetch = async (): Promise<void> => {
      if (offer) {
        setTitle(`Kassa · Lastning`);

        const { provider } = await request(offers.read({ id: offer }));

        setTitle(`Kassa · ${provider.name}`);
      }
    };

    onFetch();
  }, []);

  React.useEffect(() => {
    const onCheckQuery = (): void => {
      const address = parseQuery<Query>(location.search);

      setQuery(address);
      setAddress(address);
    };

    onCheckQuery();
  }, [location.search]);

  // ev: React.FormEvent<EventTarget>
  const onSubmit = async (): Promise<void> => {
    // ev.preventDefault();

    if (offer) {
      /* eslint-disable @typescript-eslint/camelcase */
      await requestOrder(
        orders.create({
          offer_id: offer,
          data: fields,
          apartament,
          address: cleanEmptyStrings({
            ...query,
            apartament,
          }),
          ...(addressChanged
            ? cleanEmptyStrings({
                changed_address: {
                  ...address,
                  apartament,
                },
              })
            : {}),
        }),
      );
      /* eslint-enable @typescript-eslint/camelcase */

      return history.push(
        getRoute('CheckoutFinishPage', {
          status: 'success',
          type: params.type,
          provider: data.provider.codename,
          query: { name: data.name },
        }),
      );
    }
  };

  const onShowSearch = (): void => setShowSearch(true);
  const onCloseSearch = (): void => setShowSearch(false);
  const onChangeApartament = ({ target }: React.ChangeEvent<HTMLInputElement>): void => setApartament(target.value);
  const onChangeTermsChecked = ({ target }: CheckboxChangeEvent): void => setTermsChecked(target.checked);
  const onChangePolicyChecked = ({ target }: CheckboxChangeEvent): void => setPolicyChecked(target.checked);
  const onSubmitAddress = (newAddress: Addresses): void =>
    window.location.assign(`${address.origin}${parseObject(newAddress)}`);

  const onChangeField = React.useCallback(
    (codename: string, value: any): void =>
      setFields(oldFields => ({
        ...oldFields,
        [codename]: value,
      })),
    [],
  );

  const onChangeAddress = React.useCallback((codename: string, value: any): void => {
    setAddressChanged(true);

    setAddress(oldAddress => ({
      ...oldAddress,
      [codename]: value,
    }));
  }, []);

  const onGoBack = (): void => {
    const $address = { ...address };

    if ('origin' in $address) {
      delete $address.origin;
    }

    window.location.href = `${address.origin}${parseObject($address)}`;
  };

  const providerFields = has<FieldInterface[], []>(data, 'provider.fields', []);

  const errorsData = React.useMemo(() => errors.data || {}, [errors]);

  const errorsAddress = React.useMemo(
    () => (errors.address && Object.keys(errors.address).length > 0 ? errors.address : errors.changed_address || {}),
    [errors],
  );

  const pricing =
    has<Pricing, null>(data, 'pricing', null) ||
    ({
      // eslint-disable-next-line @typescript-eslint/camelcase
      price_12_months: {},
      // eslint-disable-next-line @typescript-eslint/camelcase
      price_x_months: {},
    } as Pricing);

  return (
    <Layout contentVerticalFluid>
      <Card verticalFluid>
        <CardHeader
          className={`zh-card-header-${showSearch ? 'show' : 'hide'}-address`}
          toolbar={
            <>
              {!showSearch && Object.keys(query).length > 0 && (
                <Tag
                  className="checkout-go-back-tag"
                  value={getAddressFromObject(query, ['origin'])}
                  onClick={onShowSearch}
                  action="Byt adress"
                />
              )}

              {showSearch && (
                <Seeking provider="search" defaultAddress={query} onSubmit={onSubmitAddress} onClose={onCloseSearch} />
              )}
            </>
          }
        >
          <small className="header-go-back" onClick={onGoBack}>
            <span className="go-back-arrow">&raquo;</span> Gå tillbaka
          </small>

          <h3 className="title">
            <span className={loading ? 'skeleton skeleton-text' : ''}>
              {has<string>(data, 'provider.name', '')} · {data.name}
            </span>
          </h3>
        </CardHeader>

        <CardContent className="checkout-content">
          <Loader loading={loading} hideChildren>
            <Animated startFrom={300}>
              <Row type="flex">
                <Col span={12}>
                  <DescriptionListHeader className="big">Kostnad</DescriptionListHeader>

                  <table>
                    <tbody>
                      {pricing.price_x_months && pricing.price_x_months.per_month !== pricing.price ? (
                        <>
                          <tr>
                            <td>Kampanjpris</td>
                            <td>
                              {`${formatMoney(
                                pricing.price_x_months.per_month || 0,
                              )} kr ${`i ${pricing.price_x_months.months} månader`}`}
                            </td>
                          </tr>

                          <tr>
                            <td>Därefter</td>
                            <td>{`${formatMoney(pricing.price || 0)} kr/mån`}</td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td>Pris</td>
                          <td>{`${formatMoney(pricing.price || 0)} kr/mån`}</td>
                        </tr>
                      )}

                      <tr>
                        <td>
                          Jämförpris
                          <Tooltip
                            title={
                              <div className="center">
                                Jämförpriset är summan av alla månadsavgifter under första året, utslaget per månad.
                              </div>
                            }
                          >
                            <Icon type="question-circle" theme="filled" />
                          </Tooltip>
                        </td>

                        <td>{`${formatMoney(pricing.price_12_months.per_month || 0)} kr`}</td>
                      </tr>

                      <tr>
                        <td>Startavgift</td>

                        <td>{`${formatMoney(pricing.starting_fee || 0)} kr`}</td>
                      </tr>

                      {pricing.shipping ? (
                        <tr>
                          <td>Frakt- och miljöavgift</td>

                          <td>{`${formatMoney(pricing.shipping || 0)} kr`}</td>
                        </tr>
                      ) : null}

                      <tr className="total-tr">
                        <td>Totalt</td>
                        <td>{`${formatMoney(pricing.price_12_months.total || 0)} kr första året`}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col span={12} className="text-center">
                  {has<string>(data, 'provider.logo', '') ? (
                    <img src={`${env('HOST')}/${data.provider.logo}`} alt="provider-logo" className="provider-logo" />
                  ) : null}
                </Col>
              </Row>

              <Divider className="divider-grey" />

              <Row type="flex">
                <Col span={24}>
                  <DescriptionListHeader className="big">Detaljer</DescriptionListHeader>

                  <table className="details">
                    <tbody>
                      <tr>
                        <td>
                          <div className="label">Typ</div>
                          {has<string>(data, 'broadband_type.name', '---')}
                        </td>

                        <td>
                          <div className="label">Bindningstid</div>
                          {data.binding_time} mån
                        </td>

                        <td>
                          <div className="label">Ingår router?</div>
                          {data.has_router ? 'Ja' : 'Nej'}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div className="label">Hastighet</div>
                          <div>Nedladdning: {has<string>(data, 'speed.name', '---')}</div>
                          <div>Uppladdning: {data.speed_up ? `${data.speed_up} Mbit/s` : '---'}</div>
                        </td>

                        <td>
                          <div className="label">Uppsägningstid</div>
                          {data.notice_time ? `${data.notice_time} mån` : '0 mån'}
                        </td>

                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>

              <Divider className="divider-grey" />

              <form className="order-form">
                <Row type="flex">
                  <Col span={10}>
                    <DescriptionListHeader className="big">Dina uppgifter</DescriptionListHeader>
                  </Col>

                  <Col span={14}>
                    <Row type="flex">
                      <Col span={12}>
                        {providerFields
                          .filter(({ codename }) => codename.match('first_name'))
                          .map(item => (
                            <Form.Item
                              {...validate(errorsData, item.codename)}
                              colon={false}
                              label={
                                <span>
                                  {item.name}

                                  {/* {item.props.required ? <small>*</small> : null} */}
                                </span>
                              }
                              key={item.id}
                              className={['boolean'].includes(item.type) ? 'form-item-fluid' : ''}
                            >
                              <Field item={item} onChange={onChangeField} placeholder={item.props.label} />
                            </Form.Item>
                          ))}
                      </Col>

                      <Col span={12}>
                        {providerFields
                          .filter(({ codename }) => codename.match('last_name'))
                          .map(item => (
                            <Form.Item
                              {...validate(errorsData, item.codename)}
                              colon={false}
                              label={
                                <span>
                                  {item.name}

                                  {/* {item.props.required ? <small>*</small> : null} */}
                                </span>
                              }
                              key={item.id}
                              className={['boolean'].includes(item.type) ? 'form-item-fluid' : ''}
                            >
                              <Field item={item} onChange={onChangeField} placeholder={item.props.label} />
                            </Form.Item>
                          ))}
                      </Col>
                    </Row>

                    {providerFields
                      .filter(({ codename }) => !codename.match('first_name|last_name'))
                      .map(item => (
                        <Row type="flex" key={item.codename}>
                          <Col span={20}>
                            <Form.Item
                              {...validate(errorsData, item.codename)}
                              colon={false}
                              label={
                                <span>
                                  {item.name}

                                  {/* {item.props.required ? <small>*</small> : null} */}
                                </span>
                              }
                              key={item.id}
                              className={['boolean'].includes(item.type) ? 'form-item-fluid' : ''}
                            >
                              <Field item={item} onChange={onChangeField} placeholder={item.props.label} />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                  </Col>
                </Row>

                <Divider className="divider-grey" />

                <Row type="flex">
                  <Col span={10}>
                    <DescriptionListHeader className="big">Leveransadress</DescriptionListHeader>
                  </Col>

                  <Col span={14}>
                    <Row type="flex">
                      <Col span={22}>
                        <Form.Item
                          colon={false}
                          label="Gatuadress"
                          {...validate(errorsAddress, ['street', 'street_number', 'letter'])}
                        >
                          <Input.Group compact>
                            <Input
                              style={{ width: '70%' }}
                              placeholder={`${query.street || ''}`}
                              value={address.street}
                              onChange={({ target }): void => onChangeAddress('street', target.value)}
                            />

                            <Input
                              style={{ width: '15%', borderRadius: 0 }}
                              placeholder={`${query.street_number || '000'}`}
                              value={address.street_number}
                              onChange={({ target }): void => onChangeAddress('street_number', target.value)}
                            />

                            <Input
                              style={{ width: '15%' }}
                              placeholder={`${query.letter || 'B'}`}
                              value={address.letter}
                              onChange={({ target }): void => onChangeAddress('letter', target.value)}
                            />
                          </Input.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row type="flex">
                      <Col span={18}>
                        <Form.Item colon={false} label="Postnummer" {...validate(errorsAddress, 'postal_code')}>
                          <Input
                            placeholder={`${query.postal_code || ''}`}
                            value={address.postal_code}
                            onChange={({ target }): void => onChangeAddress('postal_code', target.value)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row type="flex">
                      <Col span={18}>
                        <Form.Item colon={false} label="Postort" {...validate(errorsAddress, 'locality')}>
                          <Input
                            placeholder={`${query.locality || ''}`}
                            value={address.locality}
                            onChange={({ target }): void => onChangeAddress('locality', target.value)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row type="flex">
                      <Col span={18}>
                        <Form.Item colon={false} label="Lägenhetsnummer" {...validate(errorsAddress, 'apartament')}>
                          <Input placeholder="1001" onChange={onChangeApartament} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Divider className="divider-grey" />

                <Row type="flex">
                  <Col span={18}>
                    <Form.Item className="form-item-fluid">
                      <Checkbox className="checkbox-small" checked={termsChecked} onChange={onChangeTermsChecked}>
                        Jag godkänner de allmäna villkoren för{' '}
                        {has<string>(data, 'provider.terms', '') ? (
                          <a href={data.provider.terms} target="_blank" rel="noopener noreferrer">
                            {has<string>(data, 'provider.name', '')}
                          </a>
                        ) : (
                          has<string>(data, 'provider.name', '')
                        )}
                      </Checkbox>
                    </Form.Item>

                    <Form.Item className="form-item-fluid">
                      <Checkbox className="checkbox-small" checked={policyChecked} onChange={onChangePolicyChecked}>
                        Jag godkänner integritetspolicy för{' '}
                        {has<string>(data, 'provider.integrity', '') ? (
                          <a href={data.provider.integrity} target="_blank" rel="noopener noreferrer">
                            {has<string>(data, 'provider.name', '')}
                          </a>
                        ) : (
                          has<string>(data, 'provider.name', '')
                        )}
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item className="text-right">
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!termsChecked || !policyChecked}
                        loading={loadingOrder}
                        onClick={onSubmit}
                      >
                        Beställ
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </form>
            </Animated>
          </Loader>
        </CardContent>
      </Card>
    </Layout>
  );
};

export default CheckoutPage;
