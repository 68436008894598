import * as React from 'react';
import { Icon } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useRequest } from 'estafette';
import { activity } from 'libs/http/api/management';
import { ActivityGetSearchResponse, Activity } from 'libs/http/api/management/activity';
import { getAddressFromObject } from 'apps/results-app/libs/string';
import { Link } from 'libs/router';
import { Time } from 'ui/atoms';
import { Table } from 'ui/organisms';
import { PrivateLayout } from 'ui/templates';

const columns: ColumnProps<Activity>[] = [
  {
    dataIndex: 'address',
    title: 'Address',
    render: (_, { address }): React.ReactNode => (
      <strong className="iconed-text">
        <Icon type="environment" theme="filled" />
        {getAddressFromObject(address)}
      </strong>
    ),
  },
  {
    dataIndex: 'offers_count',
    title: "Offers's count",
  },
  {
    dataIndex: 'date_created',
    title: 'Created at',
    render: (_, { date_created: date }): React.ReactNode => {
      const dateObject = new Date(date);

      return <Time noWrap date={`${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString()}`} noParse />;
    },
  },
  {
    title: 'Actions',
    width: 200,
    align: 'center',
    render: (_, { id }): React.ReactNode => (
      <Link route="ActivityDetailsManagementPage" params={{ id }}>
        Details
      </Link>
    ),
  },
];

const ActivitySearchManagementPage: React.FC = () => {
  const { request, data, loading } = useRequest<ActivityGetSearchResponse>();
  const [page, setPage] = React.useState<number>(1);

  React.useEffect(() => {
    request(activity.getSearch({ page }));
  }, [page]);

  const pagination = React.useMemo(
    () => ({
      pageSize: 15,
      total: data.total,
      current: page,
      onChange: (newPage: number): void => setPage(newPage),
    }),
    [data.pages, page],
  );

  return (
    <PrivateLayout header={<h1>Offers search activity</h1>} loading={loading} className="no-padding">
      <Table rowKey="id" pagination={pagination} columns={columns} dataSource={data.data} />
    </PrivateLayout>
  );
};

export default ActivitySearchManagementPage;
