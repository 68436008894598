import * as React from 'react';

import './_SkyBar.scss';

interface Props {
  percentage: number;
}

let timer: NodeJS.Timeout;
export const SkyBar: React.FC<Props> = ({ percentage: $percentage }) => {
  const [percentage, setPercentage] = React.useState(0);

  React.useEffect(() => {
    const onChangePercentage = (): void => {
      clearTimeout(timer);

      timer = setTimeout(() => setPercentage($percentage), 250);
    };

    onChangePercentage();
  }, [$percentage]);

  return (
    <div className="ra-skybar-container">
      <div className={`ra-skybar-pattern ${percentage === 100 ? 'filled' : ''}`} style={{ width: `${percentage}%` }} />

      <div className="ra-skybar-bar" />
    </div>
  );
};
