/* eslint-disable */
export function has<T, D = T>(baseObj: any, pathToAttr: string, defaultValue: D): T | D {
  return (
    (typeof pathToAttr === 'string' &&
      pathToAttr
        .replace(/\[|\]\[|\]\./g, '.')
        .split('.')
        .reduce((prev, curr) => prev && prev[curr], baseObj)) ||
    defaultValue
  );
}
/* eslint-enable */

type ValidateStatus = '' | 'success' | 'error' | 'warning' | 'validating' | undefined;

interface ValidateError {
  validateStatus: ValidateStatus;
  help: string | string[];
}

export const validate = (
  errors: { [key: string]: any },
  key: string | string[],
  validateStatus?: ValidateStatus,
): ValidateError | null => {
  if (Array.isArray(key)) {
    let error: ValidateError = {} as ValidateError;

    key.forEach($key => {
      if (errors[$key]) {
        if (Object.keys(error).length === 0) {
          error = {
            validateStatus: validateStatus || 'error',
            help: errors[$key],
          };
        } else {
          error = {
            ...error,
            help: [...(Array.isArray(error.help) ? error.help : [error.help]), ...errors[$key]],
          };
        }
      }
    });

    if (error && error.help && Array.isArray(error.help) && error.help.length > 0) {
      error.help = error.help.join(', ');
    }

    return error;
  }

  if (typeof key === 'string' && errors[key]) {
    if (Array.isArray(errors[key])) {
      return {
        validateStatus: validateStatus || 'error',
        help: errors[key],
      };
    }

    const errorsKeys = Object.keys(errors[key]);

    if (errorsKeys.length > 0) {
      return {
        validateStatus: validateStatus || 'error',
        help: errors[key][0],
      };
    }

    return null;
  }

  return null;
};
