/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from '../../axios';
import { Offer } from '../offers';

const { baseURL } = axios.defaults;

export interface ActivityGetSearchParams {
  page?: string | number;
}

export interface Activity {
  id: string;
  filters: {
    speed: string;
    broadband_type: string;
  };
  address: {
    id: number;
    street: string;
    street_number: string;
    postal_code: string;
    locality: string;
    county: string;
    municipality: string;
    last_search_date: string;
    date_created: string;
  };
  date_created: string;
  offers_count: string;
}

export interface ActivityGetSearchResponse {
  pages: number;
  total: number;
  data: Activity[];
}

export interface ActivityReadResponse {
  id: string;
  filters: {
    speed: string;
    broadband_type: string;
  };
  address: {
    id: string;
    street: string;
    street_number: string;
    postal_code: string;
    locality: string;
    county: string;
    municipality: string;
    last_search_date: string;
    date_created: string;
  };
  date_created: string;
  offers_count: string;
  offers: Offer[];
}

export const activity = {
  getSearch: (params: ActivityGetSearchParams) =>
    axios.get<ActivityGetSearchResponse>(`${baseURL}/admin/activity/search`, { params }),
  read: ({ id }: { id: string }) => axios.get<ActivityReadResponse>(`${baseURL}/admin/activity/search/${id}`),
};
