/* eslint-disable @typescript-eslint/camelcase */

// FIXME: fix type bellow
export const placeholder: any = {
  id: 3061,
  provider: {
    id: 3,
    name: 'Telenor',
    codename: 'telenor',
    logo: null,
    terms: '1',
    integrity: 'Integrity',
    description: 'Description',
    price_description: 'Price description',
    order_url: 'http://127.0.0.1:8000/',
    on_page_order: true,
    scrapper_active: true,
    active: true,
    fields: [
      {
        id: 2,
        name: 'Test boolean',
        codename: 'test_boolean',
        type: 'boolean',
        props: {
          required: false,
          help_text: 'Test boolean help',
        },
      },
      {
        id: 4,
        name: 'Test integer',
        codename: 'test_integer',
        type: 'integer',
        props: {},
      },
      {
        id: 3,
        name: 'Test float',
        codename: 'test_float',
        type: 'float',
        props: {},
      },
      {
        id: 1,
        name: 'Test email',
        codename: 'test_email',
        type: 'email',
        props: {
          required: true,
          max_length: 20,
          default: 'test_default@test_default.test_default',
          label: 'Test email custom label',
          help_text: 'Email help text',
        },
      },
      {
        id: 5,
        name: 'Test char',
        codename: 'test_char',
        type: 'char',
        props: {
          max_length: 10,
          help_text: 'Test',
          default: 'Default test',
        },
      },
      {
        id: 6,
        name: 'Test date',
        codename: 'test_date',
        type: 'date',
        props: {
          format: '%d-%m-%Y',
        },
      },
      {
        id: 7,
        name: 'Test date time',
        codename: 'test_date_time',
        type: 'date_time',
        props: {
          format: '%Y-%m-%d %H:%M',
        },
      },
      {
        id: 8,
        name: 'Test choice',
        codename: 'test_choice',
        type: 'choice',
        props: {
          choices: ['test1', 'test2', 'test3'],
        },
      },
      {
        id: 9,
        name: 'Test read only',
        codename: 'test_read_only',
        type: 'read_only',
        props: {
          default: 'Read only test',
        },
      },
      {
        id: 10,
        name: 'Test address',
        codename: 'address_test',
        type: 'char',
        props: {
          max_length: 10,
          help_text: 'Test',
          default: 'Default test',
        },
      },
      {
        id: 11,
        name: 'Test addess details',
        codename: 'address_details_test',
        type: 'char',
        props: {
          max_length: 10,
          help_text: 'Test',
          default: 'Default test',
        },
      },
    ],
  },
  speed: {
    id: 16,
    name: '1000 Mbit/s',
    codename: '1000_mbit_sek',
    value: 1000,
  },
  broadband_type: {
    id: 3,
    name: 'Fiber',
    codename: 'fiber',
    value: 'Fiber',
  },
  network: {
    id: 46,
    name: 'Universal',
    codename: 'universal',
    value: 'Universal',
  },
  pricing: {
    price: 709,
    order_price: 0,
    starting_fee: 249,
    shipping: 0,
    price_x_months: null,
    price_12_months: {
      per_month: 709,
      total: 8508,
      per_month_value_discount: null,
      per_month_percent_discount: null,
    },
    routers: [
      {
        name: 'Router D-Link DIR-842',
        months: 0,
        per_month: 0,
        per_month_value_discount: null,
        per_month_percent_discount: null,
      },
    ],
    price_value_discount: null,
    price_percent_discount: null,
    order_price_value_discount: null,
    order_price_percent_discount: null,
    starting_fee_value_discount: null,
    starting_fee_percent_discount: null,
    shipping_value_discount: null,
    shipping_percent_discount: null,
  },
  name: 'Bredband 1000/500 Mbit/s',
  speed_up: 500,
  description: 'Bredband 1000/500 Mbit/s',
  notice_time: 0,
  binding_time: 0,
  data_amount: null,
  save_surf: false,
  additional_info: '',
  has_router: true,
  invoice: [],
};
