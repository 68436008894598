import * as React from 'react';

import './_Tag.scss';

interface Props {
  style?: { [key: string]: any };
  onClick?: () => void;
  action?: string;
  value: string;
  className?: string;
}

export const Tag: React.FC<Props> = ({ style, value, onClick, action, className = '' }) => (
  <div style={style} className={`ra-tag ra-tag-iconed ${className} ${onClick !== undefined ? 'ra-tag-clickable' : ''}`}>
    <svg x="0px" y="0px" width="14pt" height="20pt" viewBox="0 0 512 512" xmlSpace="preserve">
      <g>
        <g>
          <path
            fill="#595959"
            d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035    c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719    c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>

    <span title={value}>{value}</span>

    {onClick && action && (
      <span className="ra-tag-action" onClick={onClick}>
        {action}

        <span className="ra-tag-action-arrow">&raquo;</span>
      </span>
    )}
  </div>
);
