import * as React from 'react';
import { Icon, Switch, Select } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useParams, useHistory } from 'react-router-dom';
import { useRequest } from 'estafette';
import { nomenclatures } from 'libs/http/api/management';
import { Nomenclature } from 'libs/http/api/management/nomenclatures';
import { Link, getRoute } from 'libs/router';
import { Table } from 'ui/organisms';
import { PrivateLayout } from 'ui/templates';

import './_NomenclaturesManagementPage.scss';

const NomenclaturesManagementPage: React.FC = () => {
  const { nomenclature } = useParams();
  const history = useHistory();
  const { request, data, loading } = useRequest<Nomenclature[]>();
  const { request: requestDefault, loading: loadingDefault } = useRequest<Nomenclature[]>();
  const [type, setType] = React.useState<string>('broadband_types');

  React.useEffect(() => {
    if (nomenclature) {
      request(nomenclatures.get({ nomenclature }));
    }
  }, [nomenclature]);

  const onChangeChecked = async (id: number, checked: boolean): Promise<void> => {
    if (nomenclature) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      await requestDefault(nomenclatures.patch({ id, nomenclature, is_active: checked }));
      request(nomenclatures.get({ nomenclature }));
    }
  };

  const onChangeType = (newType: string): void => {
    history.push(getRoute('NomenclaturesManagementPage', { nomenclature: newType }));
    setType(newType);
  };

  const columns: ColumnProps<Nomenclature>[] = [
    {
      dataIndex: 'name',
      title: 'Name',
      render: (_, { name }): React.ReactNode => <span className="title">{name}</span>,
    },
    {
      title: 'Actions',
      width: 300,
      align: 'center',
      render: (_, { id, is_active: checked }): React.ReactNode => (
        <>
          <div className="nomenclature-set-default">
            <Switch
              size="small"
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              checked={checked}
              onChange={(checked): Promise<void> => onChangeChecked(id, checked)}
              disabled={loadingDefault}
            />

            <span className="nomenclature-set-default-text">Active</span>
          </div>

          <Link route="NomenclatureManagementPage" params={{ update: id, nomenclature }}>
            Edit
          </Link>
        </>
      ),
    },
  ];

  return (
    <PrivateLayout
      header={
        <>
          <h1>Nomenclatures</h1>

          <div className="header-filters">
            <Select value={type} onChange={onChangeType} style={{ width: 200 }}>
              <Select.Option value="broadband_types">Broadband</Select.Option>
              <Select.Option value="speeds">Speeds</Select.Option>
            </Select>
          </div>
        </>
      }
      loading={loading && data.length === 0}
      className="no-padding"
    >
      <Table rowKey="id" columns={columns} dataSource={data} />
    </PrivateLayout>
  );
};

export default NomenclaturesManagementPage;
