import * as React from 'react';
import { Table as AntdTable } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';

import './_Table.scss';

interface Props {
  rowKey?: string;
  className?: string;
  columns: { [key: string]: any }[];
  dataSource: { [key: string]: any }[];
  rowSelection?: { [key: string]: any };
  pagination?: PaginationProps;
}

export const Table: React.FC<Props> = ({ className = '', pagination, ...props }) => (
  <AntdTable
    className={`informer-table ${className}`}
    pagination={{ hideOnSinglePage: true, position: 'bottom', pageSize: 25, ...pagination }}
    {...props}
  />
);
