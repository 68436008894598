import * as React from 'react';
import AnimateHeight from 'react-animate-height';

interface Props {
  startFrom?: number;
  debounce?: number;
  className?: string;
}

let timer: NodeJS.Timeout;
export const Animated: React.FC<Props> = ({ children, startFrom = '0%', debounce = 200, className = '' }) => {
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const onStopLoading = (): void => {
      timer = setTimeout(() => setLoading(false), debounce);
    };

    onStopLoading();

    return (): void => {
      timer && clearTimeout(timer);
    };
  }, []);

  return (
    <AnimateHeight height={loading ? startFrom : 'auto'} className={className}>
      {children}
    </AnimateHeight>
  );
};
