import * as React from 'react';
import { Statuses as SocketStatuses } from '../../libs/useSocket';
import { APIHOST } from '../../config';
import { Providers } from '../..';

import './_ProvidersLoader.scss';

interface Props {
  socketStatus: SocketStatuses;
  providers: Providers;
  providersLoading: boolean;
  loading?: boolean;
  label?: string;
  hideChildren?: boolean;
  children?: React.ReactNode;
  height?: number;
}

export const ProvidersLoader: React.FC<Props> = ({
  socketStatus,
  providers,
  providersLoading,
  height,
  loading,
  label,
  hideChildren,
  children,
}) => {
  const providerKeys = React.useMemo(() => Object.keys(providers), [providers]);
  const isSocketConnected = React.useMemo(() => socketStatus === 'connected', [socketStatus]);

  return (
    <div className="ra-loader-container" style={{ height: loading ? height : undefined }}>
      {loading && (
        <>
          <div className="ra-loader-spin-container">
            <div className="ra-loader-spin" />

            {isSocketConnected && label && <div className="ra-loader-spin-label">{label}</div>}

            {!isSocketConnected && (
              <div className="ra-loader-spin-label">Vänta... Vi försöker ansluta till våra tjänster</div>
            )}
          </div>

          <div className={`ra-loader-workers-container ${!isSocketConnected ? 'disconnected' : ''}`}>
            {providerKeys.map(key => (
              <div key={key} className="ra-loader-worker">
                <img src={`${APIHOST}/${providers[key].logo}`} alt="Telenor" />

                {isSocketConnected ? (
                  <span>
                    {providersLoading
                      ? `Vänta… Söker erbjudanden från ${providers[key].name}`
                      : `Erbjudanden från ${providers[key].name}!`}
                  </span>
                ) : (
                  <span>Vänta... Hämtar erbjudanden från {providers[key].name}</span>
                )}
              </div>
            ))}
          </div>
        </>
      )}

      {hideChildren && loading ? null : children}
    </div>
  );
};
