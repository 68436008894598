import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'estafette';
import { Row, Col, Form, Divider } from 'antd';
import { activity } from 'libs/http/api/management';
import { ActivityReadResponse } from 'libs/http/api/management/activity';
import { getAddressFromObject } from 'apps/results-app/libs/string';
import { has } from 'libs/object';
import { Time } from 'ui/atoms';
import { Offer } from 'ui/organisms';
import { PrivateLayout } from 'ui/templates';

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 15 },
};

const ActivityDetailsManagementPage: React.FC = () => {
  const { id } = useParams();
  const { request, data, loading } = useRequest<ActivityReadResponse>({ data: { offer: [] } });

  React.useEffect(() => {
    if (id) {
      request(activity.read({ id }));
    }
  }, []);

  const { offers = [] } = data;

  return (
    <PrivateLayout header={<h1>Activity Search details</h1>} loading={loading}>
      <Row type="flex">
        <Col span={12}>
          <Form {...formItemLayout} className="vertical-form">
            <Form.Item label="Created at">
              <Time date={data.date_created} />
            </Form.Item>
          </Form>
        </Col>

        <Col span={12}>
          <Form {...formItemLayout} className="vertical-form">
            <Form.Item label="Adress">
              {' '}
              <strong>{getAddressFromObject(data.address)}</strong>{' '}
            </Form.Item>
          </Form>
        </Col>
      </Row>

      {offers.map(offer => (
        <React.Fragment key={offer.id}>
          <Divider />
          <h2>{offer.name}</h2>
          <Offer key={offer.id} data={offer} speed={has<string>(data, 'speed.name', '')} />
        </React.Fragment>
      ))}
    </PrivateLayout>
  );
};

export default ActivityDetailsManagementPage;
