/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Row, Col, Form } from 'antd';
import { useRequest } from 'estafette';
import { scrappers } from 'libs/http/api/management';
import { Scrapper } from 'libs/http/api/management/scrappers';
import { getAddressFromObject } from 'apps/results-app/libs/string';
import { getRoute } from 'libs/router';
import { Time, Button } from 'ui/atoms';
import { PrivateLayout } from 'ui/templates';

import { Logger } from '../organisms';

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 15 },
};

const ActivityParserManagementPage: React.FC = () => {
  const { id } = useParams();
  const { request, data, loading } = useRequest<Scrapper>({ data: { data: {} } });

  React.useEffect(() => {
    if (id) {
      request(scrappers.read({ id }));
    }
  }, []);

  const dateObject = React.useMemo(() => (data.date_created ? new Date(data.date_created) : null), [data.date_created]);

  const onOpenPhoto = (): void => {
    const $data = `data:image/png;base64,${data.photo || ''}`;
    const w = window.open('about:blank');
    const image = new Image();

    image.src = $data;

    setTimeout(function() {
      if (w) {
        w.document.write(image.outerHTML);
      }
    }, 0);
  };

  return (
    <PrivateLayout header={<h1>Worker Activity details</h1>} loading={loading}>
      <Row type="flex">
        <Col span={12}>
          <Form {...formItemLayout} className="vertical-form">
            <Form.Item label="Worker">
              <strong>{data.worker}</strong>
            </Form.Item>

            <Form.Item label="Created at">
              {dateObject ? (
                <Time noWrap date={`${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString()}`} noParse />
              ) : (
                'loading..'
              )}
            </Form.Item>

            <Form.Item label="Photo report">
              {data.photo ? (
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={onOpenPhoto}
                  width="300"
                  src={`data:image/png;base64,${data.photo}`}
                  alt=""
                />
              ) : (
                '---'
              )}
            </Form.Item>
          </Form>
        </Col>

        <Col span={12}>
          <Form {...formItemLayout} className="vertical-form">
            <Form.Item label="Adress">
              {data.data.address ? <strong>{getAddressFromObject(data.data.address)}</strong> : '---'}
            </Form.Item>

            <Form.Item label="Type">
              <Badge status={data.log_type !== 'error' ? 'warning' : 'error'} text={data.log_type} />
            </Form.Item>

            <Form.Item label="Testing">
              {data.data.address ? (
                <a
                  href={getRoute('ProvidersListPage', { query: data.data.address })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="primary">Try it</Button>
                </a>
              ) : (
                '---'
              )}
            </Form.Item>
          </Form>
        </Col>

        <Logger message={data.message} />
      </Row>
    </PrivateLayout>
  );
};

export default ActivityParserManagementPage;
