import * as React from 'react';
import { getAddressFromObject } from '../../libs/string';
import { getRandom } from '../../libs/array';
import { Statuses as SocketStatuses } from '../../libs/useSocket';
import { Offer, Addresses } from '../../types';
import { Nomenclatures, State, Providers } from '../../';
import { ProvidersLoader, Tag, CountDown, Count, SkyBar, Select } from '../../atoms';
import { Seeking, ProviderItem, Slider, Checkbox } from '../../organisms';

import './_ResultsTemplate.scss';

interface Props {
  parsedDate: string;
  socketStatus: SocketStatuses;
  workers: number | null;
  workersProvider: string[];
  providers: Providers;
  fallback: boolean;
  loading: boolean;
  pureData: Offer[];
  data: Offer[];
  address: Addresses;
  addressId: number;
  showSearch: boolean;
  onCloseSearch: () => void;
  onShowSearch: () => void;
  filter: State;
  speeds: string[] | undefined;
  notFound: string | boolean;
  onChangeFilter: (type: 'speeds' | 'broadbands' | 'types' | 'sorts', value: any, notFiltered?: boolean) => void;
  nomenclatures: Nomenclatures;
  filterLoading: boolean;
  onSubmit: (address: Addresses) => void;
  hasMobileFilter: boolean;
}

let providersInterval: NodeJS.Timeout;
let loadingTimer: NodeJS.Timeout;
export const ResultsTemplate: React.FC<Props> = React.memo<Props>(
  ({
    parsedDate,
    socketStatus,
    workers,
    workersProvider,
    providers,
    fallback,
    loading: $loading,
    pureData = [],
    data = [],
    address = {},
    addressId,
    showSearch,
    onCloseSearch,
    onShowSearch,
    filter,
    speeds,
    notFound,
    onChangeFilter,
    nomenclatures,
    filterLoading,
    onSubmit,
    hasMobileFilter,
  }) => {
    const [currentProviders, setCurrentProviders] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [filteredProviders, setFilteredProviders] = React.useState<Providers>({} as Providers);

    React.useEffect(() => {
      return (): void => {
        clearInterval(providersInterval);
        clearTimeout(loadingTimer);
      };
    }, []);

    React.useEffect(() => {
      const onChangeCurrentProvider = (): void => setCurrentProviders(workersProvider.length);

      onChangeCurrentProvider();
    }, [workersProvider]);

    React.useEffect(() => {
      const onChangeLoading = (): void => {
        const prevLoading = Boolean(workers !== 0 || (workers === null && $loading));

        if (!prevLoading && loading) {
          clearTimeout(loadingTimer);

          setCurrentProviders(Object.keys(providers).length);

          loadingTimer = setTimeout(() => setLoading(false), 1000);
        } else {
          setLoading(true);
        }
      };

      onChangeLoading();
    }, [workers, $loading]);

    React.useEffect(() => {
      const onFilterProviders = (): void => {
        clearInterval(providersInterval);

        if ($loading || (!notFound && !$loading && data.length === 0)) {
          if (workersProvider.length === 0) {
            providersInterval = setInterval(() => onFilterProviders(), 5000);
          }

          const providerKeys = Object.keys(providers);
          const $filteredProviders: Providers = {} as Providers;

          const $workersProvider =
            workersProvider.length > 0
              ? workersProvider.filter((_, key) => key <= 3)
              : getRandom(
                  providerKeys.map((key: any) => providers[key].codename),
                  3,
                );

          providerKeys
            .filter(key => $workersProvider.includes(key))
            .forEach(key => ($filteredProviders[key] = providers[key]));

          setFilteredProviders($filteredProviders);
        }
      };

      onFilterProviders();
    }, [workersProvider, notFound, $loading, data, providers]);

    const totalProviders = React.useMemo(() => Object.keys(providers).length, [providers]);
    const isInvalidDate = React.useMemo(() => parsedDate === 'okänd', [parsedDate]);

    return (
      <>
        <div className={`zh-card-header zh-card-header-${showSearch ? 'show' : 'hide'}-address`}>
          <div className="zh-card-header-content">
            <h3 className="title">Detta kan du välja mellan</h3>

            {loading ? (
              <div className="no-margin ra-parsing-loader">
                <span className="ra-parsing-loader-text">
                  <Count size="big" loading />

                  <span>
                    (Kontrollerat <CountDown total={currentProviders} /> av{' '}
                    <Count loading={!totalProviders} count={totalProviders} /> leverantörer)
                  </span>
                </span>

                <SkyBar percentage={(currentProviders * 100) / totalProviders} />
              </div>
            ) : (
              <p className="no-margin grey">
                <span>
                  Vi hittade{' '}
                  <b>
                    <CountDown total={data.length} />
                  </b>{' '}
                  bredbandsabonnemang som matchar dina val.
                </span>

                <span className={`parsed-time ${!parsedDate || isInvalidDate ? 'parsed-time-loading' : ''}`}>
                  Priserna kontrollerades senast
                  <strong> {!isInvalidDate ? `idag ${parsedDate}` : parsedDate}.</strong>
                </span>
              </p>
            )}
          </div>

          {toolbar && (
            <div className="zh-card-header-toolbar">
              {!showSearch && Object.keys(address).length > 0 && (
                <Tag value={getAddressFromObject(address)} onClick={onShowSearch} action="Byt adress" />
              )}

              {showSearch && (
                <Seeking provider="search" defaultAddress={address} onSubmit={onSubmit} onClose={onCloseSearch} />
              )}
            </div>
          )}
        </div>

        <div className="zh-card-header-divider" />

        <div className="zh-card-header-filter">
          <div className="zh-card-header-filter-item">
            <span className="label">
              <span>Hastighet: </span>
              <span className="regular">Minst </span>
              <b>{filter.speeds} Mbit/s</b>
            </span>

            <Slider
              directions
              directionLabel="Mbit/s"
              speeds={speeds}
              loading={loading}
              disabled={pureData.length === 0}
              value={filter.speeds}
              onChange={(value, notFiltered): void => onChangeFilter('speeds', value, notFiltered)}
              hasMobileFilter={hasMobileFilter}
            />
          </div>

          <div className="zh-card-header-filter-item">
            <span className="label">Typ</span>

            <Checkbox
              loading={loading}
              placeholders={['Fast bredband', 'Mobilt bredband']}
              options={Object.keys(nomenclatures.broadbands).map(value => ({
                ...nomenclatures.broadbands[value],
                value,
              }))}
              value={filter.broadbands}
              onChange={(value): void => onChangeFilter('broadbands', value)}
            />
          </div>

          <div className="zh-card-header-filter-item">
            <span className="label">Visa endast</span>

            <Checkbox
              loading={loading}
              options={Object.keys(nomenclatures.types).map(value => ({
                ...nomenclatures.types[value],
                value,
              }))}
              value={filter.types}
              onChange={(value): void => onChangeFilter('types', value)}
            />
          </div>

          <div className="zh-card-header-filter-item">
            <span className="label">Sortera efter</span>

            <Select
              loading={loading}
              options={Object.keys(nomenclatures.sorts).map(value => ({
                ...nomenclatures.sorts[value],
                value,
              }))}
              value={filter.sorts}
              onChange={(value): void => onChangeFilter('sorts', value)}
            />
          </div>
        </div>

        <div className="zh-card-header-divider" />

        <ProvidersLoader
          socketStatus={socketStatus}
          providers={filteredProviders}
          providersLoading={workersProvider.length === 0}
          loading={$loading || (!notFound && !$loading && data.length === 0)}
          label="Hämtar erbjudanden..."
          hideChildren
        >
          {notFound && data.length === 0 ? (
            <h2 className="empty-text">
              {typeof notFound === 'string' ? notFound : 'Vi hittar tyvärr inga erbjudanden för denna adress.'}
            </h2>
          ) : (
            <>
              <div className={`ra-results-fallback ${fallback ? 'active' : ''}`}>
                <div className="ra-results-fallback-text">
                  Vi hittade inget tillgängligt fast bredband på din adress. Om du vet att detta finns, meddela oss
                  gärna på <a href="mailto://kontakt@bredband.nu">kontakt@bredband.nu</a>
                </div>
              </div>

              {data.map(item => (
                <ProviderItem
                  address={address}
                  addressId={addressId}
                  loading={filterLoading}
                  key={item.id}
                  data={item}
                />
              ))}
            </>
          )}
        </ProvidersLoader>
      </>
    );
  },
);
