/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from '../../axios';

const { baseURL } = axios.defaults;

export interface Field<T = string> {
  key: number;
  id: number;
  name: string;
  codename: string;
  type: T;
  props: {
    [key: string]: any;
  };
}

export type FullField = Field<{
  id: number;
  name: string;
  codename: string;
}>;

export const field = {
  get: () => axios.get<FullField[]>(`${baseURL}/admin/fields`),
};
