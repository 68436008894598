/* eslint-disable */
export function has<T, D = T>(baseObj: any, pathToAttr: string, defaultValue: D): T | D {
  return (
    (typeof pathToAttr === 'string' &&
      pathToAttr
        .replace(/\[|\]\[|\]\./g, '.')
        .split('.')
        .reduce((prev, curr) => prev && prev[curr], baseObj)) ||
    defaultValue
  );
}
/* eslint-enable */

export function parseQuery<T>(queryString: string): T {
  if (queryString !== '') {
    const query: any = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

    let i = 0;
    for (i; i < pairs.length; i += 1) {
      const pair = pairs[i].split('=');

      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return query;
  }

  return {} as T;
}

export const parseObject = (obj: { [key: string]: any }): string => {
  const str = [];

  // eslint-disable-next-line
  for (const p in obj) {
    if (p) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p] || '')}`);
    }
  }

  return `${str.length > 0 ? '?' : ''}${str.join('&')}`;
};

export function keys<O>(o: O): (keyof O)[] {
  return Object.keys(o) as (keyof O)[];
}

export function cleanEmptyStrings<T>(obj: T): T {
  const $obj: any = {};

  // remove empty string
  keys<T>(obj).forEach(key => ($obj[key] = obj[key] || null));

  return $obj;
}
