import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Form } from 'antd';
import { useRequest } from 'estafette';
import { orders } from 'libs/http/api/management';
import { Order } from 'libs/http/api/management/orders';
import { has } from 'libs/object';
import { Offer } from 'ui/organisms';
import { PrivateLayout } from 'ui/templates';

import './_OrderManagementPage.scss';

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const OrderManagementPage: React.FC = () => {
  const { id } = useParams();
  const { request, data, loading } = useRequest<Order>({ data: { offer: {}, data: {} } });

  React.useEffect(() => {
    if (id) {
      request(orders.read({ id }));
    }
  }, []);

  const offer = data.offer || {};

  return (
    <PrivateLayout
      header={
        <h1 className={loading ? 'grey' : ''}>
          {loading
            ? 'Loading ...'
            : `${has<string>(offer, 'provider.name', '')} ${offer.name ? '· ' : ''}${offer.name || ''}`}
        </h1>
      }
      loading={loading}
    >
      <Offer data={offer} speed={has<string>(data, 'speed.name', '')} />

      <hr />

      <Row type="flex">
        <Col span={12}>
          <h2>Fields:</h2>

          <Form {...formItemLayout} className="vertical-form">
            {Object.keys(data.data).map(key => (
              <Form.Item key={key} label={key} className="provider-fields">
                {data.data[key]}
              </Form.Item>
            ))}
          </Form>
        </Col>
      </Row>
    </PrivateLayout>
  );
};

export default OrderManagementPage;
