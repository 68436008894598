import * as React from 'react';

import './_Loader.scss';

interface Props {
  loading?: boolean;
  hideChildren?: boolean;
  children?: React.ReactNode;
  height?: number;
}

export const Loader: React.FC<Props> = ({ height = 300, loading, hideChildren, children }) => (
  <div className="ra-loader-container" style={{ height: loading ? height : undefined }}>
    {loading && <div className="loader-spin" />}

    {hideChildren && loading ? null : children}
  </div>
);
