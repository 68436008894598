/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AxiosRequestConfig } from 'axios';
import axios from '../axios';
import { Address } from './offers';

const { baseURL } = axios.defaults;

export interface Options {
  address: Address;
  changed_address?: Address;
  apartament?: number | string;
  offer_id: string | number;
  data: { [key: string]: string };
}

export const orders = {
  create: (options: Options & AxiosRequestConfig) => axios.post<{}>(`${baseURL}/orders`, options),
};
