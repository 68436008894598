import * as React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { Link } from 'libs/router';
import { Loader, Animated } from 'ui/atoms';

import './_PrivateLayout.scss';

interface Props {
  header?: React.ReactNode;
  loading?: boolean;
  className?: string;
}

export const PrivateLayout: React.FC<Props> = ({ children, header, loading = false, className = '' }) => (
  <Layout>
    <Layout.Sider collapsed className="private-layout-slider">
      <div className="logo" />

      <Menu theme="dark" mode="inline">
        <Menu.Item key="1">
          <Link route="ProvidersManagementPage">
            <Icon type="golden" theme="filled" />

            <span className="nav-text">Providers</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="2">
          <Link route="OrdersManagementPage">
            <Icon type="schedule" theme="filled" />

            <span className="nav-text">Orders</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="3">
          <Link route="ActivitySearchManagementPage">
            <Icon type="dashboard" theme="filled" />

            <span className="nav-text">Activities</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="4">
          <Link route="ActivityParsersManagementPage">
            <Icon type="alert" theme="filled" />

            <span className="nav-text">Workers Activities</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>

    <Layout className="private-layout">
      {header && <Layout.Header className="private-layout-header">{header}</Layout.Header>}

      <Layout.Content className={`private-layout-content ${className}`}>
        <Loader loading={loading} hideChildren>
          <Animated startFrom={211}>{children}</Animated>
        </Loader>
      </Layout.Content>
    </Layout>
  </Layout>
);
