import * as React from 'react';

import './_Suspense.scss';

export const Suspense: React.FC<{ type?: string }> = ({ type = 'small' }) => (
  <div className={`suspense suspense-type-${type}`}>
    {type === 'wide' && <div className="suspense-block wide"></div>}
    {type === 'wide-vertical-fluid' && <div className="suspense-block wide vertical-fluid"></div>}

    {type === 'small' && <div className="suspense-block small"></div>}
    {type === 'small-vertical-fluid' && <div className="suspense-block small vertical-fluid"></div>}

    {type === 'medium' && <div className="suspense-block medium"></div>}
    {type === 'medium-vertical-fluid' && <div className="suspense-block medium vertical-fluid"></div>}
  </div>
);
