import * as React from 'react';

import './_Card.scss';

interface Props {
  type?: 'wide' | 'small' | 'medium';
  className?: string;
  verticalFluid?: boolean;
  id?: string;
}

interface HeaderProps {
  noPadding?: boolean;
  toolbar?: React.ReactNode;
  className?: string;
  classToolbar?: string;
}

export const Card: React.FC<Props> = ({ className = '', type = 'default', ...props }) => (
  <div className={`card card-${type} ${props.verticalFluid ? 'vertical-fluid' : ''} ${className}`} id={props.id}>
    {props.children}
  </div>
);

export const CardHeader: React.FC<HeaderProps> = ({
  noPadding,
  toolbar,
  className = '',
  classToolbar = '',
  ...props
}) => (
  <div className={`zh-card-header ${noPadding ? 'no-padding' : ''} ${className}`}>
    <div className="zh-card-header-content">{props.children}</div>

    {toolbar && <div className={`zh-card-header-toolbar ${classToolbar}`}>{toolbar}</div>}
  </div>
);

export const CardContent: React.FC<{ noPadding?: boolean; className?: string }> = ({
  noPadding,
  className = '',
  ...props
}) => <div className={`card-content ${className} ${noPadding ? 'no-padding' : ''}`}>{props.children}</div>;
