import * as React from 'react';
import { PrivacyLock } from '../../atoms';
import { Seeking } from '../../organisms';
import { Addresses } from '../../types';

import './_SearchTemplate.scss';
import { parseObject } from 'apps/results-app/libs/object';

interface Props {
  defaultAddress: Addresses | {};
}

export const SearchTemplate: React.FC<Props> = React.memo<Props>(({ defaultAddress }) => {
  const onSubmit = (address: Addresses): void => {
    const application = document.getElementById('results-app');
    if (application) {
      window.location.href = `${application.getAttribute('data-redirect-url') || ''}${parseObject(address)}`;
    }
  };

  return (
    <div className="ra-seeking-card">
      <h1 className="center">Jämför bredband.</h1>
      <p className="center">Snabbt, enkelt och gratis. Alltid utan bindning.</p>

      <Seeking defaultAddress={defaultAddress} onSubmit={onSubmit} />

      <div className="ra-seeking-privacy">
        <PrivacyLock />

        <span>Vi skyddar din integritet. Alla uppgifter på skärmen krypteras via en säker anslutning.</span>
      </div>
    </div>
  );
});
