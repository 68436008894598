/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from '../../axios';
import { Offer } from '../offers';

const { baseURL } = axios.defaults;

export interface OrdersGetParams {
  page?: string | number;
}

export interface Order {
  id: number;
  offer: Offer;
  address: {
    id: number;
    street: string;
    street_number: string;
    postal_code: string;
    locality: string;
    county: string;
    municipality: string;
    last_search_date: string;
    date_created: string;
  };
  provider: {
    id: number;
    name: string;
    codename: string;
    logo: string;
  };
  speed: {
    id: number;
    name: string;
    codename: string;
    value: number;
  };
  network: {
    id: number;
    name: string;
    codename: string;
    value: string;
  };
  broadband_type: {
    id: number;
    name: string;
    codename: string;
    value: string;
  };
  offer_id: number;
  data: { [key: string]: any };
  date_created: string;
  send_to_nett: { sent: { [key: string]: any }; status: number; content: string; date_send: string };
}

export interface OrdersGetResponse {
  pages: number;
  total: number;
  data: Order[];
}

export const orders = {
  get: (params: OrdersGetParams) => axios.get<OrdersGetResponse>(`${baseURL}/admin/orders`, { params }),
  read: ({ id }: { id: number | string }) => axios.get<Order>(`${baseURL}/admin/orders/${id}`),
  resend: ({ id }: { id: number | string }) => axios.post(`${baseURL}/admin/orders/resend`, { id }),
};
