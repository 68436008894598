/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from '../axios';

const { baseURL } = axios.defaults;

export interface Nomenclatures {
  id: number;
  name: string;
  codename: string;
  default: boolean;
}

export const nomenclatures = {
  get: ({ nomenclature, addressId }: { nomenclature: string; addressId?: string | number }) =>
    fetch(`${baseURL}/nomenclatures/${nomenclature}?address_id=${addressId}`).then(res => res.json()),
};
