import * as React from 'react';
import env from '@beam-australia/react-env';
import { useParams, useHistory } from 'react-router-dom';
import { useRequest } from 'estafette';
import { ChromePicker, ColorResult } from 'react-color';
import { Input, Form, Switch, Icon, Upload, Popconfirm } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { Provider } from 'libs/http/api/management/providers';
import { providers, field as fieldApi } from 'libs/http/api/management';
import { FullField } from 'libs/http/api/management/field';
import { validate } from 'libs/object';
import { Link, getRoute } from 'libs/router';
import { Button } from 'ui/atoms';
import { PrivateLayout } from 'ui/templates';

import { ProviderField } from '../../organisms';

import './_ProviderManagementPage.scss';

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 6 },
};

const ProviderManagementPage: React.FC = () => {
  const history = useHistory();
  const { update } = useParams();
  const { request, errors, loading } = useRequest();
  const { request: requestFields, data: dataFields, loading: loadingFields } = useRequest<FullField[]>();
  const { request: requestProvider, data: dataProvider, loading: loadingProvider } = useRequest<Provider>();

  const [name, setName] = React.useState<string>('');
  const [terms, setTerms] = React.useState<string>('');
  const [integrity, setIntegrity] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [priceDescription, setPriceDescription] = React.useState<string>('');
  const [orderUrl, setOrderUrl] = React.useState<string>('');
  const [prefixOrderUrl, setPrefixOrderUrl] = React.useState<string>('');
  const [onPageOrder, setOnPageOrder] = React.useState<boolean>(false);
  const [hideOrderButton, setHideOrderButton] = React.useState<boolean>(false);
  const [active, setActive] = React.useState<boolean>(false);
  const [logo, setLogo] = React.useState<any>(null);
  const [defaultLogo, setDefaultLogo] = React.useState<any>(null);
  const [field, setField] = React.useState<boolean>(false);
  const [fieldsId, setFieldsId] = React.useState<number[]>([]);
  const [fieldUpdate, setFieldUpdate] = React.useState<number | null>(null);
  const [orderColor, setOrderColor] = React.useState<string>('#53d624');
  const [orderUrlColor, setOrderUrlColor] = React.useState<string>('#1789e6');
  const [pickOrderColor, setPickOrderColor] = React.useState<boolean>(false);
  const [pickOrderUrlColor, setPickOrderUrlColor] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (update && update !== 'add') {
      requestProvider(providers.read({ id: update }));
    }

    requestFields(fieldApi.get());
  }, []);

  React.useEffect(() => {
    const onMergeData = (): void => {
      setName(dataProvider.name);
      setTerms(dataProvider.terms);
      setIntegrity(dataProvider.integrity);
      setDescription(dataProvider.description);
      setPriceDescription(dataProvider.price_description);
      setOrderColor(dataProvider.order_color || '#53d624');
      setOrderUrl(dataProvider.order_url);
      setPrefixOrderUrl(dataProvider.prefix_order_url);
      setOrderUrlColor(dataProvider.order_url_color || '#1789e6');
      setOnPageOrder(dataProvider.on_page_order);
      setHideOrderButton(dataProvider.hide_order_button);
      setActive(dataProvider.active);
      setDefaultLogo(dataProvider.logo);

      if (dataProvider.fields) {
        setFieldsId(dataProvider.fields.map(({ id }) => id));
      }
    };

    onMergeData();
  }, [dataProvider]);

  const onSubmit = async (ev?: React.FormEvent<EventTarget>, continueEdit?: boolean): Promise<void> => {
    if (ev) {
      ev.preventDefault();
    }

    if (update) {
      const formData: any = new FormData();

      formData.append('name', name);
      formData.append('terms', terms);
      formData.append('integrity', integrity);
      formData.append('description', description);
      formData.append('active', active ? '1' : '0');
      formData.append('price_description', priceDescription);
      formData.append('order_color', orderColor || '');
      formData.append('order_url', orderUrl || '');
      formData.append('prefix_order_url', prefixOrderUrl || '');
      formData.append('order_url_color', orderUrlColor || '');
      formData.append('on_page_order', onPageOrder ? '1' : '0');
      formData.append('hide_order_button', hideOrderButton ? '1' : '0');

      if (logo) {
        formData.append('logo', logo);
      }

      // fields always has to be with something
      if (fieldsId.length > 0) {
        fieldsId.forEach((id: number | string | null) => formData.append('fields', id));
      } else {
        formData.append('fields', []);
      }

      await request(providers.put(update, formData));

      if (!continueEdit) {
        history.push(getRoute('ProvidersManagementPage'));
      }
    }
  };

  const onChangeLogo = (info: UploadChangeParam<UploadFile<any>>): void => {
    const { status } = info.file;

    if (!status) {
      setLogo(info.file);
    }
  };

  const onOpenField = React.useCallback((id?: number): void => {
    setField(true);

    if (id) {
      setFieldUpdate(id);
    }
  }, []);

  const onCloseField = (): void => {
    setField(false);
    setFieldUpdate(null);
  };

  const onChangeField = React.useCallback(
    async (action: 'add' | 'edit' | 'delete', value: number): Promise<void> => {
      let newFieldsId: number[] = [...fieldsId];

      if (action === 'add') {
        newFieldsId.push(value);
      } else if (action === 'edit') {
        newFieldsId = [...newFieldsId.filter(id => fieldUpdate !== id), value];
      } else if (action === 'delete') {
        newFieldsId = [...newFieldsId.filter(id => value !== id)];
      }

      setFieldsId(newFieldsId);
    },
    [fieldsId],
  );

  const onRemoveLogo = (): void => setLogo(null);
  const onChangeName = ({ target }: React.ChangeEvent<HTMLInputElement>): void => setName(target.value);
  const onChangeTerms = ({ target }: React.ChangeEvent<HTMLInputElement>): void => setTerms(target.value);
  const onChangeDescription = ({ target }: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setDescription(target.value);
  const onChangePriceDescription = ({ target }: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setPriceDescription(target.value);
  const onChangeIntegrity = ({ target }: React.ChangeEvent<HTMLInputElement>): void => setIntegrity(target.value);
  const onChangeOrderUrl = ({ target }: React.ChangeEvent<HTMLInputElement>): void => setOrderUrl(target.value);
  const onPrefixOrderUrl = ({ target }: React.ChangeEvent<HTMLInputElement>): void => setPrefixOrderUrl(target.value);
  const onChangeOnPageOrder = (checked: boolean): void => setOnPageOrder(checked);
  const onChangeHideOrderButton = (checked: boolean): void => setHideOrderButton(checked);
  const onChangeActive = (checked: boolean): void => setActive(checked);
  const onChangeOrderColor = ({ hex }: ColorResult): void => setOrderColor(hex);
  const onChangeOrderUrlColor = ({ hex }: ColorResult): void => setOrderUrlColor(hex);
  const onToggleOrderColor = (): void => setPickOrderColor(s => !s);
  const onToggleOrderUrlColor = (): void => setPickOrderUrlColor(s => !s);

  return (
    <>
      {field && (
        <ProviderField fields={dataFields} record={fieldUpdate} onSubmit={onChangeField} onClose={onCloseField} />
      )}

      <PrivateLayout header={<h1>Provider edit</h1>} loading={loadingProvider || loadingFields}>
        <Form {...formItemLayout} className="vertical-form" onSubmit={onSubmit}>
          <Form.Item label="Name" {...validate(errors, 'name')}>
            <Input value={name} onChange={onChangeName} />
          </Form.Item>

          <Form.Item label="Terms (URL)" {...validate(errors, 'terms')}>
            <Input value={terms} onChange={onChangeTerms} />
          </Form.Item>

          <Form.Item label="Privacy (URL)" {...validate(errors, 'integrity')}>
            <Input value={integrity} onChange={onChangeIntegrity} />
          </Form.Item>

          <Form.Item label="Description" {...validate(errors, 'description')}>
            <Input.TextArea rows={8} value={description} onChange={onChangeDescription} />
          </Form.Item>

          <Form.Item label="Price description" {...validate(errors, 'price_description')}>
            <Input.TextArea rows={8} value={priceDescription} onChange={onChangePriceDescription} />
          </Form.Item>

          <Form.Item label="Regular order color" {...validate(errors, 'order_color')} className="provider-picker-input">
            {pickOrderColor ? (
              <div className="provider-picker-wrapper left">
                <div className="provider-picker-mask" onClick={onToggleOrderColor} />

                <ChromePicker color={orderColor} onChange={onChangeOrderColor} />
              </div>
            ) : (
              <button
                type="button"
                onClick={onToggleOrderColor}
                className="provider-picker-button"
                style={{ backgroundColor: orderColor || '#53d624' }}
              />
            )}
          </Form.Item>

          <Form.Item label="Order Url" {...validate(errors, 'order_url_color')} className="provider-picker-input">
            <Input value={orderUrl} onChange={onChangeOrderUrl} />

            {pickOrderUrlColor ? (
              <div className="provider-picker-wrapper">
                <div className="provider-picker-mask" onClick={onToggleOrderUrlColor} />

                <ChromePicker color={orderUrlColor} onChange={onChangeOrderUrlColor} />
              </div>
            ) : (
              <button
                type="button"
                onClick={onToggleOrderUrlColor}
                className="provider-picker-button"
                style={{ backgroundColor: orderUrlColor || '#1789e6' }}
              />
            )}
          </Form.Item>

          <Form.Item
            label="Prefix Order Url"
            {...validate(errors, 'prefix_order_url')}
            className="provider-picker-input"
          >
            <Input value={prefixOrderUrl} onChange={onPrefixOrderUrl} />
          </Form.Item>

          <Form.Item label="Order on page" className="form-item-compact" {...validate(errors, 'on_page_order')}>
            <Switch checked={onPageOrder} onChange={onChangeOnPageOrder} />
          </Form.Item>

          <Form.Item label="Hide order button" className="form-item-compact" {...validate(errors, 'hide_order_button')}>
            <Switch checked={hideOrderButton} onChange={onChangeHideOrderButton} />
          </Form.Item>

          <Form.Item label="Active" {...validate(errors, 'active')}>
            <Switch checked={active} onChange={onChangeActive} />
          </Form.Item>

          <Form.Item label="Logo" {...validate(errors, 'logo')}>
            {defaultLogo && (
              <div className="provider-dafault-logo">
                <img src={`${env('HOST')}/${defaultLogo}`} alt="logo" className={loading ? 'skeleton-img' : ''} />
              </div>
            )}

            <Upload.Dragger
              name="file"
              multiple={false}
              fileList={logo ? [logo] : []}
              onChange={onChangeLogo}
              onRemove={onRemoveLogo}
              beforeUpload={(): false => false}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>

              <p className="ant-upload-text">Click or drag image to this area to upload</p>

              <p className="ant-upload-hint">Support for a single upload.</p>
            </Upload.Dragger>
          </Form.Item>

          <Form.Item label="Fields" {...validate(errors, 'fields')} className="provider-fields">
            {dataFields
              .filter(({ id }) => fieldsId.includes(id))
              .map(item => (
                <div className="provider-field" key={item.key || item.id}>
                  <Popconfirm
                    title="Are you sure delete this field?"
                    onConfirm={(): Promise<void> => onChangeField('delete', item.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Icon type="close" className="provider-field-delete" />
                  </Popconfirm>

                  <b>{item.name}</b>

                  <div className="provider-field-action" onClick={(): void => onOpenField(item.id)}>
                    Edit
                    <Icon type="double-right" />
                  </div>
                </div>
              ))}

            <div className="provider-field">
              <div className="provider-field-action" onClick={(): void => onOpenField()}>
                Add
              </div>
            </div>
          </Form.Item>

          <Form.Item colon={false} label=" " className="form-item-button">
            <Link route="ProvidersManagementPage">
              <Button>Back</Button>
            </Link>

            <Button
              loading={loading}
              onClick={(): Promise<void> => onSubmit(undefined, true)}
              className="button-long-text"
            >
              Save and continue editing
            </Button>

            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </PrivateLayout>
    </>
  );
};

export default ProviderManagementPage;
