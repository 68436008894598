import * as React from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { FullField } from 'libs/http/api/management/field';

import './_ProviderField.scss';

interface Props {
  onClose: () => void;
  onSubmit: (action: 'add' | 'edit', value: number) => void;
  record: number | null;
  fields: FullField[];
}

export const ProviderField: React.FC<Props> = ({ onClose, record, fields, ...props }) => {
  const [fieldId, setFieldId] = React.useState<number | null>(null);
  const [name, setName] = React.useState<string>('');
  const [codename, setCodename] = React.useState<string>('');

  React.useEffect(() => {
    const onChangeField = (): void => {
      const target = fields.find(item => fieldId === item.id);
      if (target && target.id) {
        setFieldId(target.id);
        setName(target.name);
        setCodename(target.codename);
      }
    };

    onChangeField();
  }, [fieldId, fields]);

  React.useEffect(() => {
    const onSetInitialFieldId = (): void => {
      if (record) {
        setFieldId(record);
      }
    };

    onSetInitialFieldId();
  }, [record]);

  const onSubmit = (): void => {
    if (fieldId) {
      props.onSubmit(record ? 'edit' : 'add', fieldId);

      onClose();
    }
  };

  const onChangeFieldId = (value: number): void => setFieldId(value);

  return (
    <Modal className="modal-footer-center" title={false} visible onCancel={onClose} onOk={onSubmit}>
      <Form>
        <Form.Item label="Type">
          <Select placeholder="Select field's type" value={fieldId || undefined} onChange={onChangeFieldId}>
            {fields.map(item => (
              <Select.Option value={item.id} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Name">
          <Input value={name} disabled />
        </Form.Item>

        <Form.Item label="Code name">
          <Input value={codename} disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};
