import * as React from 'react';
import Logo from './resources/logo.svg';

import './_Layout.scss';

interface Props {
  className?: string;
  classContent?: string;
  classHeader?: string;
  contentVerticalFluid?: boolean;
}

export const Layout: React.FC<Props> = ({ className = '', classHeader = '', ...props }) => {
  const stickyRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  React.useEffect(() => {
    let lastScroll = 0;
    const handleScroll = (): void => {
      const { current: header = {} }: any = stickyRef;
      const currentScroll = window.pageYOffset;

      if (header) {
        if (currentScroll <= 35) {
          header.classList.remove('scroll-up');
          return;
        }

        if (currentScroll > lastScroll && !header.classList.contains('scroll-down')) {
          // down
          header.classList.remove('scroll-up');
          header.classList.add('scroll-down');
        } else if (currentScroll < lastScroll && header.classList.contains('scroll-down')) {
          // up
          header.classList.remove('scroll-down');
          header.classList.add('scroll-up');
        }

        lastScroll = currentScroll;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`layout ${className}`}>
      <header className={`layout-header ${classHeader}`} ref={stickyRef}>
        <LayoutContent>
          <a href="/">
            <img src={Logo} alt="Logotype of website" className="layout-header-logo" />
          </a>
        </LayoutContent>
      </header>

      <LayoutContent className={props.classContent} contentVerticalFluid={props.contentVerticalFluid}>
        {props.children}
      </LayoutContent>
    </div>
  );
};

export const LayoutContent: React.FC<{ className?: string; contentVerticalFluid?: boolean }> = ({
  className = '',
  contentVerticalFluid,
  children,
}) => (
  <div className={`layout-content ${contentVerticalFluid ? 'layout-content-vertical-fluid' : ''} ${className}`}>
    {children}
  </div>
);
