import * as React from 'react';
import { Card } from 'ui/atoms';
import { Layout } from 'ui/templates';
import 'apps/results-app';

const ProvidersListPage: React.FC = () => (
  <Layout contentVerticalFluid>
    <Card type="small" id="results-app-parent">
      <div id="results-app" data-redirect-url="" />
    </Card>
  </Layout>
);

export default ProvidersListPage;
