/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AxiosRequestConfig } from 'axios';
import axios from '../axios';
import { Field } from './management/field';
// import { placeholder } from 'features/checkout/placeholder';

const { baseURL } = axios.defaults;

export interface Address {
  street?: string | null;
  street_number?: string | null;
  postal_code?: string | null;
  locality?: string | null;
  county?: string | null;
  municipality?: string | null;
  apartament?: string;
  start_date?: string;
}

export interface OffersGetOptions {
  token: string;
  limit?: number | null;
  offset?: number | null;
  address: Address;
  speed?: string | null;
  broadband_type?: string | null;
}

export interface Offer {
  id: number;
  provider: {
    id: number;
    name: string;
    codename: string;
    description: string;
    price_description: string;
    logo: string | null;
    order_color?: string;
    order_url?: string;
    order_url_color?: string;
    hide_order_button?: boolean;
    fields?: {
      id: number;
      name: string;
      codename: string;
      type: string;
      props: Field;
    }[];
  };
  speed: {
    id: number;
    value: number;
    name: string;
    codename: string;
  };
  broadband_type: {
    id: number;
    name: string;
    codename: string;
  };
  network: {
    id: number;
    name: string;
    codename: string;
  };
  pricing: {
    price: number;
    order_price: number;
    starting_fee: number;
    shipping: number;
    price_x_months: {
      per_month: number;
      total: number;
      months: number;
      per_month_value_discount: number | null;
      per_month_percent_discount: number | null;
    } | null;
    price_12_months: {
      per_month: number;
      total: number;
      per_month_value_discount: number | null;
      per_month_percent_discount: number | null;
    };
    routers: {
      name: string;
      months: number;
      per_month: number;
      per_month_value_discount: number | null;
      per_month_percent_discount: number | null;
    }[];
    price_value_discount: number | null;
    price_percent_discount: number | null;
    order_price_value_discount: number | null;
    order_price_percent_discount: number | null;
    starting_fee_value_discount: number | null;
    starting_fee_percent_discount: number | null;
    shipping_value_discount: number | null;
    shipping_percent_discount: number | null;
  };
  name: string;
  speed_up: number;
  speed_down?: number;
  description: string;
  notice_time: number;
  binding_time: number;
  data_amount: string;
  save_surf: boolean;
  additional_info: string;
  has_router: boolean;
  invoice: string[] | null;
  order_color?: string;
  order_url?: string;
  order_url_color?: string;
}

interface OffersReadOptions {
  id: number | string;
}

export const offers = {
  read: ({ id, ...params }: OffersReadOptions & AxiosRequestConfig) =>
    axios.get<Offer>(`${baseURL}/offers/${id}`, { params }),
};
