import * as React from 'react';
import { APIHOST, HOST } from '../../config';
import { formatMoney } from '../../libs/number';
import { parseObject, has } from '../../libs/object';
import { Offer, Pricing, Addresses } from '../../types';
import { Arrow, External } from '../../resources';
import { Button, Price, DescriptionList, DescriptionListItem, DescriptionListHeader } from '../../atoms';

import './_ProviderItem.scss';

interface Props {
  loading?: boolean;
  data: Offer;
  address: Addresses | {};
  addressId: number;
}

const { origin } = window.location;

export const ProviderItem: React.FC<Props> = ({ loading, data, address = {} }) => {
  const [expanded, setExpand] = React.useState<boolean>(false);

  const onToggleExpand = (): false | void => !loading && setExpand(!expanded);

  const classLoading = loading ? 'skeleton skeleton-auto skeleton-text' : '';

  const isMobile = React.useMemo(
    () => data.data_amount !== undefined && data.broadband_type.codename === 'mobilnatet',
    [data.broadband_type, data],
  );

  const pricing =
    has<Pricing, null>(data, 'pricing', null) ||
    ({
      // eslint-disable-next-line @typescript-eslint/camelcase
      price_12_months: {},
    } as Pricing);

  const [type, provider] = React.useMemo(
    () => [
      data.broadband_type.codename === 'mobilnatet' ? 'mobile-broadband' : 'fiber',
      data.provider.codename.replace('_', '-'),
    ],
    [data],
  );

  return (
    <div className="ra-provider-item">
      <div className="ra-provider-item-img">
        <div className="ra-provider-item-img-container">
          {data.provider.logo && (
            <img
              src={`${APIHOST}/${data.provider.logo}`}
              alt={`${data.provider.name}s logo`}
              className={loading ? 'skeleton-img' : ''}
            />
          )}
        </div>

        <div className="ra-provider-description-show-more" onClick={onToggleExpand}>
          <span>
            <span className={`show-more-text ${classLoading}`}>
              {expanded ? 'Stäng' : 'Visa mer information'}

              <Arrow />
            </span>
          </span>
        </div>

        {expanded && (
          <div className="ra-provider-expanded-list">
            <div className="ra-provider-expanded-item">
              <span>{data.provider.name}</span>

              <span>{data.provider.description}</span>

              {data.additional_info && <span>{data.additional_info}</span>}
            </div>
          </div>
        )}
      </div>

      <div className="ra-provider-item-content">
        <div className="ra-provider-item-content-1">
          <DescriptionListHeader loading={loading}>
            {data.provider.name} · {data.broadband_type.name}{' '}
            {isMobile
              ? data.data_amount === null || data.data_amount === 0
                ? 'Obegränsad'
                : `${data.data_amount} GB`
              : data.speed.name}
          </DescriptionListHeader>

          <DescriptionList>
            <DescriptionListItem label="Nedladdning" value={`${data.speed.value} Mbit/s`} />

            <DescriptionListItem label="Uppladdning" value={`${data.speed_up} Mbit/s`} />

            <DescriptionListItem label="Bindningstid" value={`${data.binding_time} mån`} />

            <DescriptionListItem
              onClick={onToggleExpand}
              className="ra-provider-description-show-more"
              label={
                <span className={`show-more-text ${expanded ? '' : 'not-'}expanded ${classLoading}`}>
                  {expanded ? 'Stäng' : 'Visa mer information'}

                  <Arrow />
                </span>
              }
            />
          </DescriptionList>

          {expanded && (
            <div className="ra-provider-expanded-list">
              <div className="ra-provider-expanded-item">
                <span>Typ</span>
                <span>{data.broadband_type.name}</span>
              </div>

              <div className="ra-provider-expanded-item">
                <span>Hastighet</span>
                {data.speed.value && <span>Nedladdning: Upp till {data.speed.value} Mbit/s.</span>}
                <span>Uppladdning: Upp till {data.speed_up} Mbit/s.</span>
              </div>

              {isMobile && (
                <div className="ra-provider-expanded-item">
                  <span>Datamängd</span>
                  <span className="capitalize">
                    {data.data_amount === null || data.data_amount === 0 ? 'Obegränsad' : `${data.data_amount} GB`}
                  </span>
                </div>
              )}

              <div className="ra-provider-expanded-item">
                <span>Bindningstid och uppsägningstid</span>

                <span>
                  {data.binding_time && !data.notice_time
                    ? `Bindningstiden är ${data.binding_time} månader. Därefter kan du direkt säga upp avtalet utan uppsägningstid.`
                    : null}

                  {!data.binding_time && data.notice_time
                    ? `Ingen bindningstid. Du kan säga upp avtalet när du vill med ${data.notice_time} månaders uppsägningstid.`
                    : null}

                  {data.binding_time && data.notice_time
                    ? `Bindningstiden är ${data.binding_time} månader. Efter bindningstiden kan du säga upp avtalet med ${data.notice_time} månaders uppsägningstid.`
                    : null}

                  {!data.binding_time && !data.notice_time
                    ? `Ingen bindningstid eller uppsägningstid. Du kan säga upp avtalet när du vill.`
                    : null}
                </span>
              </div>

              <div className="ra-provider-expanded-item">
                <span>Ingår router?</span>
                <span>{data.has_router ? 'Ja' : 'Nej'}</span>
              </div>

              {data.has_router && data.pricing.routers.length > 0 && (
                <div className="ra-provider-expanded-item">
                  <span>Typ av router</span>
                  <span className="ra-provider-router">
                    {data.pricing.routers.length > 1 && <span className="ra-provider-router-select">Välj mellan:</span>}

                    {data.pricing.routers.map((router, key) => (
                      <span
                        className={`provider-block ${data.pricing.routers.length > 1 ? 'numeral' : ''}`}
                        key={router.name}
                      >
                        {data.pricing.routers.length > 1 && <span>{key + 1}.</span>}
                        {router.name}
                      </span>
                    ))}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="ra-provider-item-content-2">
          <div
            className={`ra-provider-action ${expanded ? 'expanded' : ''} ${
              data.provider.hide_order_button ? 'ra-provider-hidden-button' : ''
            }`}
          >
            <Price price={data.pricing.price_12_months.per_month} loading={loading} />

            {!data.provider.hide_order_button ? (
              data.order_url ? (
                <a href={`${data.order_url}`} target="_blank" rel="noopener noreferrer">
                  <Button
                    type="primary"
                    disabled={loading}
                    className={`ra-provider-extern-link gtm-order-button gtm-external-order-button gtm-order-external-${provider} gtm-order-type-${type}`}
                    style={{
                      backgroundColor: data.provider.order_url_color,
                      borderColor: data.provider.order_url_color,
                    }}
                  >
                    Beställ
                    <External />
                  </Button>
                </a>
              ) : (
                <a
                  href={`${HOST}/order/type/${type}/provider/${provider}/${data.id}${parseObject(
                    address,
                  )}&origin=${origin}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    className={`gtm-order-button gtm-order-${provider} gtm-order-type-${type}`}
                    type="primary"
                    disabled={loading}
                    style={{ backgroundColor: data.provider.order_color, borderColor: data.provider.order_color }}
                  >
                    Beställ
                  </Button>
                </a>
              )
            ) : null}
          </div>

          {expanded && (
            <div className="ra-provider-expanded-list">
              {pricing.price_x_months && pricing.price_x_months.per_month !== pricing.price ? (
                <>
                  <div className="ra-provider-expanded-item">
                    <span>Kampanjpris</span>
                    <span>
                      {`${formatMoney(
                        pricing.price_x_months.per_month || 0,
                      )} kr ${`i ${pricing.price_x_months.months} månader`}`}
                    </span>
                  </div>

                  <div className="ra-provider-expanded-item">
                    <span>Därefter</span>
                    <span>{`${formatMoney(pricing.price || 0)} kr/mån`}</span>
                  </div>
                </>
              ) : (
                <div className="ra-provider-expanded-item">
                  <span>Kostnad</span>
                  <span>{formatMoney(pricing.price || 0)} kr/mån</span>
                </div>
              )}

              <div className="ra-provider-expanded-item">
                <span>Startavgift</span>
                <span>{formatMoney(data.pricing.starting_fee)} kr</span>
              </div>

              {data.pricing.shipping ? (
                <div className="ra-provider-expanded-item">
                  <span>Frakt- och miljöavgift</span>
                  <span>{formatMoney(data.pricing.shipping)} kr</span>
                </div>
              ) : null}

              <div className="ra-provider-expanded-item marked">
                <span>Totalt</span>
                <span>{formatMoney(data.pricing.price_12_months.total)} kr/år</span>
              </div>

              {data.provider.price_description && (
                <div className="ra-provider-expanded-item-desc">
                  <span>{data.provider.price_description}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
