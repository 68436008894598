import * as React from 'react';
import { Router as BrowserRouter, Route as OldRoute, RouteProps as OldRouteProps } from 'react-router-dom';
import { history, Redirect } from 'libs/router';
import { routes, Routes } from 'routes';
import { Suspense } from 'ui/templates';

interface RouteProps extends Pick<Routes, Exclude<keyof Routes, 'name'>> {
  component: React.FC<OldRouteProps>;
}

let isMatch = false;
const Route: React.FC<RouteProps> = ({ suspense, component: Component, ...route }) => {
  // change the variable to test suspense view for route
  const testSuspense = false;
  if (testSuspense) {
    return <Suspense type="medium" />;
  }

  return (
    <OldRoute
      {...route}
      render={(routeProps): React.ReactNode => {
        isMatch = true;

        return (
          <React.Suspense fallback={<Suspense type={suspense} />}>
            <Component {...routeProps} />
          </React.Suspense>
        );
      }}
    />
  );
};

export const CreateRouter: React.FC = () => (
  <BrowserRouter history={history}>
    {routes.map(({ name, ...route }) => (
      <Route key={name} {...route} />
    ))}

    {/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */}
    <OldRoute render={() => (!isMatch ? <Redirect toRoute="ProvidersListPage" /> : null)} />
  </BrowserRouter>
);
