import * as React from 'react';

interface Props {
  type?: 'single';
}

export const Arrow: React.FC<Props> = ({ type = 'single' }) => {
  if (type === 'single') {
    return (
      <svg viewBox="0 0 8 12" width="1em" height="1em">
        <path d="M4.822,5.992 L0.293,1.527 C-0.060,1.179 -0.061,0.608 0.296,0.256 C0.655,-0.099 1.231,-0.098 1.586,0.253 L6.765,5.357 C6.941,5.531 7.029,5.760 7.030,5.990 C7.031,6.223 6.942,6.451 6.765,6.626 L1.586,11.731 C1.233,12.079 0.653,12.080 0.296,11.728 C-0.064,11.373 -0.063,10.806 0.293,10.456 L4.822,5.992 Z"></path>
      </svg>
    );
  }

  return null;
};
