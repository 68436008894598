import * as React from 'react';

import './_Checkbox.scss';

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
  exclude?: string;
  excludeAll?: boolean;
}

interface Props {
  onChange: (value: any[]) => void;
  value: any[];
  options: Option[];
  placeholders?: string[];
  loading?: boolean;
}

export const Checkbox: React.FC<Props> = ({ onChange, value, options, loading, placeholders = [] }) => {
  const [values, setValues] = React.useState<any[]>([]);

  React.useEffect(() => {
    const onSetDefaultValues = (): void => {
      if (value !== undefined) {
        setValues(value);
      }
    };

    onSetDefaultValues();
  }, [value]);

  const $options: Option[] = React.useMemo(
    () =>
      loading
        ? placeholders.length > 0
          ? placeholders.map(label => ({ label, value: label }))
          : options.map(option => ({ ...option, disabled: true }))
        : options,
    [loading, placeholders, options],
  );

  const onChangeHandler = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
    let cloneValues = [...values];

    if (cloneValues.includes(target.value)) {
      cloneValues = cloneValues.filter(item => item !== target.value);
    } else {
      cloneValues.push(target.value);
    }

    let isExcludedAll = false;
    const excludedValues: { [key: string]: string } = {};

    $options.forEach(({ value, exclude, excludeAll }) => {
      if (exclude) {
        excludedValues[value] = exclude;
      }

      if (excludeAll && cloneValues.includes(value)) {
        isExcludedAll = true;
      }
    });

    const cloneValuesReversed = cloneValues.reverse();

    if (!isExcludedAll) {
      for (let i = 0; i < cloneValuesReversed.length; i++) {
        if (excludedValues[cloneValuesReversed[i]]) {
          cloneValues = cloneValues.filter(itemValue => itemValue !== excludedValues[cloneValuesReversed[i]]);
          break;
        }
      }
    } else {
      cloneValues = cloneValues.length >= 1 ? [cloneValues[0]] : [];
    }

    setValues(cloneValues);

    onChange(cloneValues);
  };

  return (
    <div className="ra-checkbox-group">
      {$options.length === 0 ? (
        <div className="ra-checkbox-empty">Det finns inget att välja</div>
      ) : (
        $options.map(({ label, value, disabled }) => (
          <label
            key={value}
            className={`ra-checkbox ${disabled || loading ? 'ra-checkbox-disabled' : ''}`}
            title={loading ? 'Lastning ...' : ''}
          >
            <span className="ra-checkbox-label">
              <span>{label}</span>
            </span>

            <input
              type="checkbox"
              value={value}
              onChange={onChangeHandler}
              checked={!loading ? values.includes(value) : false}
              disabled={disabled || loading}
            />

            <span className="ra-checkbox-checkmark" />
          </label>
        ))
      )}
    </div>
  );
};
