import * as React from 'react';

import './_DescriptionList.scss';

interface Props {
  className?: string;
}

export const DescriptionList: React.FC<Props> = ({ children, className = '' }) => (
  <ul className={`description-list ${className}`}>{children}</ul>
);

interface ItemProps {
  className?: string;
  label?: React.ReactNode;
  value?: React.ReactNode;
  loading?: boolean;
  onClick?: () => any;
  active?: boolean;
}

export const DescriptionListItem: React.FC<ItemProps> = ({ className = '', label, value, ...props }) => (
  <li className={`description-list-item ${className} ${props.active ? 'active' : ''}`} onClick={props.onClick}>
    {label && <span>{label}</span>}

    {value && <span className={props.loading ? 'skeleton skeleton-auto skeleton-text' : ''}>{value}</span>}
  </li>
);

interface HeaderProps {
  className?: string;
  loading?: boolean;
}

export const DescriptionListHeader: React.FC<HeaderProps> = ({ className = '', ...props }) => (
  <h2 className={`description-list-header ${props.loading ? 'skeleton skeleton-auto skeleton-text' : ''} ${className}`}>
    {props.children}
  </h2>
);
