import * as React from 'react';
import { APIHOST } from '../../config';
import { Addresses } from '../../types';
import { indexedFirst, mapSuggestions } from '../../libs/array';
import { getAddressFromObject } from '../../libs/string';
import { Button } from '../../atoms';
import { Autocomplete } from '../Autocomplete/Autocomplete';

import './_Seeking.scss';

interface Props {
  onClose?: () => void;
  provider?: 'form' | 'search';
  autoFocus?: boolean;
  onRemoveErrors?: () => void;
  errors?: { [key: string]: any };
  defaultAddress: any;
  onSubmit: (address: Addresses) => void;
}

let timer: NodeJS.Timeout;
export const Seeking: React.FC<Props> = ({ onClose, provider = 'form', defaultAddress = {}, ...props }) => {
  const [data, setData] = React.useState<Addresses[]>([]);
  const [suggestions, setSuggestions] = React.useState<Addresses[]>([]);
  const [address, setAddress] = React.useState<string>('');
  const [addressObject, setAddressObject] = React.useState<Addresses>({} as Addresses);
  const [error, setError] = React.useState<string>('');
  const [addressLoading, setAddressLoading] = React.useState<number | false | null>(null);

  React.useEffect(() => {
    return (): void => {
      timer && clearTimeout(timer);
    };
  }, []);

  const onSearch = async (value: string): Promise<void> => {
    const stringValue = value.toString();

    setError('');
    setAddress(stringValue);

    if (stringValue.length >= 3) {
      timer && clearTimeout(timer);

      timer = setTimeout(
        () =>
          fetch(`${APIHOST}/api/v1/addresses/street?address=${stringValue.replace(',', ' ')}`)
            .then((res: any) => res.json())
            .then(res => (Array.isArray(res) ? setData(indexedFirst(res)) : res)),
        300,
      );
    }
  };

  React.useEffect(() => {
    const onSetDefaultAddress = (): void => {
      if (Object.keys(defaultAddress).length > 0) {
        const newDefaultAddress = `${defaultAddress.street}${
          defaultAddress.street_number ? ', ' : ''
        }${defaultAddress.street_number || ''}${defaultAddress.letter ? ' ' : ''}${defaultAddress.letter || ''}`;

        setAddress(newDefaultAddress);
        onSearch(newDefaultAddress);
      }
    };

    onSetDefaultAddress();
  }, [defaultAddress]);

  const onSelect = React.useCallback(
    (key: number, submit?: boolean): void => {
      const $data = suggestions.length > 0 ? suggestions : data;

      setError('');

      if (suggestions.length === 0) {
        setAddressLoading(key);
        setAddress(getAddressFromObject($data[key]));

        fetch(`${APIHOST}/api/v1/addresses/validate`, {
          method: 'POST',
          body: JSON.stringify($data[key]),
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
        })
          .then((res: any) => res.json())
          .then(res => {
            if (!res.is_valid) {
              setAddressLoading(false);
              setError(res.detail || '');
              setSuggestions(res.suggestions || []);
            } else {
              if (submit) {
                props.onSubmit(res.match);

                return;
              }

              setAddressLoading(null);
              setAddressObject(res.match);
              setAddress(getAddressFromObject(res.match));

              return;
            }
          });
      } else {
        setAddressLoading(null);
        setAddressObject($data[key]);
        setAddress(getAddressFromObject($data[key]));
      }
    },
    [suggestions, data],
  );

  const onSubmit = (addressKey?: number): void => {
    const $data = suggestions.length > 0 ? suggestions : data;

    if ($data.length > 0) {
      setError('');
      setSuggestions([]);

      const $addressObject =
        addressKey !== undefined ? $data[addressKey] : Object.keys(addressObject).length > 0 ? addressObject : $data[0];

      props.onSubmit($addressObject);
    } else {
      setError('Det här fältet kan inte vara tomt och du måste välja en adress i listan');
    }
  };

  return (
    <div>
      <div className={`ra-seeking-form ra-seeking-${provider}`}>
        <div className="ra-seeking-autocomplete">
          <div className="ra-seeking-autocomplete-label">Din adress:</div>

          <Autocomplete
            autoFocus
            placeholder="Sveavägen 14, 111 57 Stockholm"
            loadingSuggestion={addressLoading}
            suggestions={mapSuggestions(data)}
            alternativeMessage={suggestions.length > 0 ? error : ''}
            alternativeSuggestions={mapSuggestions(suggestions)}
            value={address}
            onPressEnter={(key): void => onSelect(key, true)}
            onChange={onSearch}
            onSelect={onSelect}
          />

          {error && <div className="ra-seeking-error">{error}</div>}

          {onClose && (
            <span className="ra-seeking-close" onClick={onClose}>
              &times;
            </span>
          )}
        </div>

        <Button onClick={(): void => onSubmit()} type="primary" size="large">
          Jämför
        </Button>
      </div>
    </div>
  );
};
