import * as React from 'react';

import './_Autocomplete.scss';

interface Suggestion {
  key?: string;
  value: any;
  label: string;
}

interface Props {
  autoFocus?: boolean;
  placeholder?: string;
  suggestions: Suggestion[];
  alternativeMessage?: string;
  alternativeSuggestions?: Suggestion[];
  onChange: (value: string) => void;
  onPressEnter: (addressKey: number) => void;
  onSelect: (addressKey: number) => void;
  value?: string;
  loadingSuggestion?: number | false | null;
}

export const Autocomplete: React.FC<Props> = ({
  alternativeMessage,
  alternativeSuggestions = [],
  suggestions: $suggestions = [],
  ...props
}) => {
  const [activeSuggestion, setActiveSuggestion] = React.useState<number>(0);
  const [showSuggestions, setShowSuggestions] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<boolean>(false);
  const [userInput, setUserInput] = React.useState<string>('');

  const suggestions = React.useMemo(() => (alternativeSuggestions.length > 0 ? alternativeSuggestions : $suggestions), [
    alternativeMessage,
    alternativeSuggestions,
    $suggestions,
  ]);

  React.useEffect(() => {
    const onSetDefaultValue = (): void => {
      if (props.value && userInput !== props.value) {
        setUserInput(props.value);
      }
    };

    onSetDefaultValue();
  }, [userInput, props.value]);

  React.useEffect(() => {
    const onHideSuggestions = (): void => {
      if (props.loadingSuggestion === null) {
        setShowSuggestions(false);
      }
    };

    onHideSuggestions();
  }, [props.loadingSuggestion]);

  React.useEffect(() => {
    const onShowSuggestions = (): void => {
      if (!selected) {
        setShowSuggestions(suggestions.length > 0);
      }
    };

    onShowSuggestions();
  }, [userInput, selected, suggestions]);

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = ev.currentTarget;

    props.onChange(value);

    setSelected(false);
    setActiveSuggestion(0);
    setUserInput(value);
  };

  const onClick = ($this: any): void => {
    if (!props.loadingSuggestion) {
      props.onSelect($this.currentTarget.getAttribute('data-index'));

      setActiveSuggestion(0);
      setSelected(true);
      setUserInput($this.currentTarget.innerText);
    }
  };

  const onKeyDown = (ev: React.KeyboardEvent): void => {
    // User pressed the enter key
    if (ev.keyCode === 13 && suggestions[activeSuggestion] && !props.loadingSuggestion) {
      props.onPressEnter(activeSuggestion);

      setActiveSuggestion(0);
      setSelected(true);
      setUserInput(suggestions[activeSuggestion].label);
    }

    // User pressed the up arrow
    else if (ev.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    }

    // User pressed the down arrow
    else if (ev.keyCode === 40) {
      if (activeSuggestion - 1 === suggestions.length) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const onHideSuggestions = (): void => setShowSuggestions(false);
  const onShowSuggestions = (): void => setShowSuggestions(true);

  const showSuggestionsBlock = React.useMemo(() => showSuggestions && userInput && suggestions.length > 0, [
    showSuggestions,
    userInput,
    suggestions,
  ]);

  return (
    <>
      {showSuggestionsBlock && <div className="ra-autocomplete-mask" onClick={onHideSuggestions} />}

      <div className="ra-autocomplete">
        <input
          autoFocus={props.autoFocus}
          className="ra-autocomplete-input"
          type="text"
          placeholder={props.placeholder}
          value={userInput}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={onShowSuggestions}
        />

        {showSuggestionsBlock ? (
          <>
            {alternativeMessage && <div className="ra-autocomplete-suggestions-message">{alternativeMessage}</div>}

            <ul className={`ra-autocomplete-suggestions ${alternativeMessage ? 'alternative' : ''}`}>
              {(alternativeSuggestions.length > 0 ? alternativeSuggestions : suggestions).map((suggestion, index) => (
                <li
                  key={suggestion.key || suggestion.value}
                  className={index === activeSuggestion ? 'active' : ''}
                  data-index={index}
                  onClick={onClick}
                  role="presentation"
                >
                  {suggestion.label}

                  {`${props.loadingSuggestion}` === `${index}` ? <span className="ra-loader-suggestion-spin" /> : null}
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
    </>
  );
};
