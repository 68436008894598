import * as React from 'react';
import env from '@beam-australia/react-env';
import { useLocation, useParams } from 'react-router-dom';
import { useRequest } from 'estafette';
import { providers } from 'libs/http/api';
import { parseQuery } from 'libs/http/url';
import { Provider } from 'libs/http/api/providers';
import { useDocumentTitle } from 'hooks';
import { Redirect } from 'libs/router';
import { Loader, Card, CardContent } from 'ui/atoms';
import { Layout } from 'ui/templates';

import './_CheckoutFinishPage.scss';

const CheckoutFinishPage: React.FC = () => {
  const params = useParams() as { [key: string]: string };
  const { request, loading } = useRequest<Provider[]>();
  const setTitle = useDocumentTitle();
  const location = useLocation();
  const [logo, setLogo] = React.useState<string>('');
  const [provider, setProvider] = React.useState<string>('');

  const query = parseQuery<{ id: string; name: string; provider: string }>(location.search);

  React.useEffect(() => {
    setTitle(`Kassa · Slutför  · ${'name' in query ? query.name : ''}`);

    const onFetchProvider = async (): Promise<void> => {
      if ('provider' in params) {
        const data = await request(providers.get());

        const { logo: $logo, name: $provider } =
          data.find(({ codename }) => params.provider === codename.replace('_', '-')) || {};

        if ($provider) {
          setProvider($provider);
        }

        if ($logo) {
          setLogo(`${env('HOST')}/${$logo}`);
        }
      }
    };

    onFetchProvider();
  }, []);

  if (!('name' in query)) {
    return <Redirect toRoute="SeekingProvidersPage" />;
  }

  return (
    <Layout>
      <Card type="medium" className="checkout-finish-content">
        <CardContent className="checkout-finish-block">
          <Loader loading={loading} height={300} hideChildren>
            <h1 className="center">Tack för din beställning!</h1>

            <p className="center">
              Du blir snart kontaktad av <strong>{provider}</strong> med bekräftelse på ditt nya bredbandsavtal
              <strong> {query.name}</strong>.
            </p>

            {logo && (
              <div className="checkout-finish-provider">
                <small>I samarbete med</small>
                <img src={logo} alt={provider} />
              </div>
            )}
          </Loader>
        </CardContent>
      </Card>
    </Layout>
  );
};

export default CheckoutFinishPage;
