import * as React from 'react';
import { Col } from 'antd';

import './_Logger.scss';

interface Props {
  message?: string;
}

export const Logger: React.FC<Props> = ({ message }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const onToggleOpen = (): void => setOpen(s => !s);

  if (!message) {
    return null;
  }

  return (
    <Col span={24} className="logger-container">
      <pre>
        {open ? message : message.substr(0, 300)} {!open ? ' ...' : ''}
      </pre>

      <div className="logger-toggler" onClick={onToggleOpen}>
        {open ? 'hide' : 'show'}
      </div>
    </Col>
  );
};
