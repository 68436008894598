/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { Badge, Icon, Divider } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useRequest } from 'estafette';
import { scrappers } from 'libs/http/api/management';
import { ScrappersGetResponse, Scrapper } from 'libs/http/api/management/scrappers';
import { Link } from 'libs/router';
import { getAddressFromObject } from 'apps/results-app/libs/string';
import { getRoute } from 'libs/router';
import { Time } from 'ui/atoms';
import { Table } from 'ui/organisms';
import { PrivateLayout } from 'ui/templates';

const columns: ColumnProps<Scrapper>[] = [
  {
    dataIndex: 'worker',
    title: 'Worker',
    render: (_, { worker }): React.ReactNode => <b>{worker}</b>,
  },
  {
    dataIndex: 'log_type',
    title: 'Type',
    render: (_, { log_type: type }): React.ReactNode => (
      <Badge status={type !== 'error' ? 'warning' : 'error'} text={type} />
    ),
  },
  {
    dataIndex: 'data.address',
    title: 'Address',
    render: (_, { data }): React.ReactNode => (
      <strong className="iconed-text">
        <Icon type="environment" theme="filled" />

        {getAddressFromObject(data.address)}
      </strong>
    ),
  },
  {
    dataIndex: 'date_created',
    title: 'Created date',
    render: (_, { date_created: date }): React.ReactNode => {
      const dateObject = new Date(date);

      return <Time noWrap date={`${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString()}`} noParse />;
    },
  },
  {
    title: 'Actions',
    width: 170,
    align: 'center',
    render: (_, { id, data }): React.ReactNode => (
      <>
        <Link route="ActivityParserManagementPage" params={{ id }}>
          Details
        </Link>

        <Divider type="vertical" />

        <a href={getRoute('ProvidersListPage', { query: data.address })} target="_blank" rel="noopener noreferrer">
          Try it
        </a>
      </>
    ),
  },
];

const ActivityParsersManagementPage: React.FC = () => {
  const { request, data, loading } = useRequest<ScrappersGetResponse>({ data: { data: [] }, loading: true });
  const [page, setPage] = React.useState<number>(1);

  React.useEffect(() => {
    request(scrappers.get({ page }));
  }, [page]);

  const pagination = React.useMemo(
    () => ({
      pageSize: 15,
      total: data.total,
      current: page,
      onChange: (newPage: number): void => setPage(newPage),
    }),
    [data.pages, page],
  );

  return (
    <PrivateLayout header={<h1>Workers Activity</h1>} loading={loading} className="no-padding">
      <Table rowKey="id" pagination={pagination} columns={columns} dataSource={data.data} />
    </PrivateLayout>
  );
};

export default ActivityParsersManagementPage;
