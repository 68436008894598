import * as React from 'react';

import './_Slider.scss';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  directions?: boolean;
  directionLabel?: string;
  speeds?: string[] | undefined;
  value?: number | string;
  onChange: (value: string, notFiltered?: boolean) => void;
  hasMobileFilter: boolean;
}

export const Slider: React.FC<Props> = React.memo(({ speeds = [], loading, directionLabel = '', value, ...props }) => {
  const [steps, setSteps] = React.useState<number[]>([]);
  const [min, setMin] = React.useState<number>(10);
  const [max, setMax] = React.useState<number>(1000);
  const [percentage, setPercentage] = React.useState<number>(10);
  const [disabled, setDisabled] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    const onResetSpeeds = (): void => {
      setMin(10);
      setMax(1000);
    };

    onResetSpeeds();
  }, [speeds]);

  React.useEffect(() => {
    const onChangeValues = (): void => {
      const initialSteps = [10, 50, 100, 250, 500, 1000];
      const $speeds = speeds.map(speed => Number(speed));

      const $steps: number[] = [];
      $speeds.forEach(speed => {
        let step = null;

        const equalStep = initialSteps.find(step => speed === step);
        if (equalStep) {
          step = equalStep;
        } else {
          const availableSteps = initialSteps.filter(step => speed >= step);

          // get last of available
          step = availableSteps[availableSteps.length - 1];
        }

        if (step && !$steps.includes(step)) {
          $steps.push(step);
        }
      });

      if ($steps.length === 1) {
        props.onChange($steps[0].toString(), true);
        setDisabled(true);
      }

      if ($steps.length >= 2) {
        setDisabled(undefined);

        const [$min, $max, $value] = [$steps[0], $steps[$steps.length - 1], Number(value)];

        setSteps($steps);
        setMin($min);
        setMax($max);

        if ($min > $value || $max < $value) {
          props.onChange($steps[0].toString(), true);
        }
      }
    };

    onChangeValues();
  }, [speeds, value]);

  React.useEffect(() => {
    const onChangePercentage = (): void => {
      const $value = Number(value);

      if (min <= $value && max >= $value) {
        setPercentage((($value - min) / (max - min)) * 100);
      }
    };

    onChangePercentage();
  }, [min, max, value]);

  const onChangeSlider = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue =
      steps.find((step, key) => {
        if (steps[key + 1]) {
          return Number(target.value) <= (step + steps[key + 1]) / 2;
        }

        return Number(target.value) <= (step + steps[key]) / 1.25;
      }) || target.value;

    if (Number(value) !== Number(newValue)) {
      props.onChange(newValue.toString());
    }
  };

  return (
    <div className="ra-slider-hint">
      {!loading && !props.hasMobileFilter && disabled !== undefined ? (
        <div className="ra-slider-hint-text">
          <div className="center">Det finns bara leverantörer med {speeds[0]} Mbit/s</div>
        </div>
      ) : null}

      <div className="ra-slider-container" title={loading ? 'Lastning ...' : ''}>
        <input
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={onChangeSlider}
          disabled={disabled !== undefined ? disabled : loading || props.disabled}
          className={`ra-slider ${loading || disabled !== undefined || props.disabled ? 'ra-slider-disabled' : ''}`}
        />

        <div
          className={`ra-slider-pattern ra-slider-pattern-${
            percentage === 0 ? 'first' : percentage === 100 ? 'last' : 'middle'
          }`}
          style={{ width: `${percentage}%` }}
        />

        {props.directions && (
          <div className="ra-slider-direction">
            <span>
              {min} {directionLabel}
            </span>

            <span>
              {max} {directionLabel}
            </span>
          </div>
        )}
      </div>
    </div>
  );
});
