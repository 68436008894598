/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from '../axios';

const { baseURL } = axios.defaults;

export interface Provider {
  id: number;
  name: string;
  logo: string;
  codename: string;
}

export const providers = {
  get: () => axios.get<Provider[]>(`${baseURL}/providers`),
};
