import { lazyRouter as lazy } from 'libs/fs';

export type Routes = {
  name: string;
  path: string;
  component: any;
  suspense?: string;
  exact?: boolean;
};

// prettier-ignore
export const routes: Routes[] = [
  { name: 'ProvidersListPage', path: '/', component: lazy('providers', 'ProvidersListPage'), suspense: 'medium-vertical-fluid', exact: true },

  { name: 'CheckoutPage', path: '/order/type/:type/provider/:provider/:offer', component: lazy('checkout', 'CheckoutPage/CheckoutPage'), suspense: 'medium-vertical-fluid', exact: true },
  { name: 'CheckoutFinishPage', path: '/order-finish/type/:type/provider/:provider', component: lazy('checkout', 'CheckoutFinishPage/CheckoutFinishPage'), suspense: 'medium', exact: true },
  
  { name: 'SigninPage', path: '/management/authentication/sign-in', component: lazy('management/authentication', 'SigninPage/SigninPage'), suspense: 'private' },

  { name: 'ProvidersManagementPage', path: '/management/providers', component: lazy('management/providers', 'ProvidersManagementPage'), suspense: 'private' },
  { name: 'ProviderManagementPage', path: '/management/provider/:update', component: lazy('management/providers', 'ProviderManagementPage/ProviderManagementPage'), suspense: 'private' },

  { name: 'OrdersManagementPage', path: '/management/orders', component: lazy('management/orders', 'OrdersManagementPage/OrdersManagementPage'), suspense: 'private' },
  { name: 'OrderManagementPage', path: '/management/order/:id', component: lazy('management/orders', 'OrderManagementPage/OrderManagementPage'), suspense: 'private' },

  { name: 'ActivitySearchManagementPage', path: '/management/activities/searches', component: lazy('management/activities', 'ActivitySearchManagementPage'), suspense: 'private' },
  { name: 'ActivityDetailsManagementPage', path: '/management/activity/:id/offers', component: lazy('management/activities', 'ActivityDetailsManagementPage'), suspense: 'private' },
  { name: 'ActivityParsersManagementPage', path: '/management/parsers-activity', component: lazy('management/activities', 'ActivityParsersManagementPage'), suspense: 'private' },
  { name: 'ActivityParserManagementPage', path: '/management/parser-activity/:id', component: lazy('management/activities', 'ActivityParserManagementPage'), suspense: 'private' },
];
