import { Dispatch, SetStateAction, useState, useEffect } from 'react';

const defaultTitle = 'Nettbureau';
const getDocumentTitle = (title?: string): string => `${title ? `${title} · ` : ''}${defaultTitle}`;

export const setDocumentTitle = (title?: string): string => (document.title = getDocumentTitle(title || defaultTitle));

export function useDocumentTitle(defaultValue?: string): Dispatch<SetStateAction<string>> {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    const onSetDefaultValue = (): void => {
      if (defaultValue) {
        setValue(defaultValue);
      }
    };

    onSetDefaultValue();

    return (): void => {
      setDocumentTitle();
    };
  }, []);

  useEffect(() => {
    setDocumentTitle(value);
  }, [value]);

  return setValue;
}
