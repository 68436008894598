import * as React from 'react';
import { Icon, Badge, Divider } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useRequest } from 'estafette';
import { orders } from 'libs/http/api/management';
import { OrdersGetResponse, Order } from 'libs/http/api/management/orders';
import { getAddressFromObject } from 'apps/results-app/libs/string';
import { Link } from 'libs/router';
import { Time } from 'ui/atoms';
import { Table } from 'ui/organisms';
import { PrivateLayout } from 'ui/templates';

import './_OrdersManagementPage.scss';

const OrdersManagementPage: React.FC = () => {
  const { request, data, loading } = useRequest<OrdersGetResponse>();
  const { request: requestResend, loading: loadingResend } = useRequest<void>();
  const [page, setPage] = React.useState<number>(1);

  React.useEffect(() => {
    request(orders.get({ page }));
  }, [page]);

  const onResendOrder = React.useCallback(async (id: string | number) => {
    await requestResend(orders.resend({ id }));

    if (page !== 1) {
      setPage(1);
    } else {
      request(orders.get({ page: 1 }));
    }
  }, []);

  const columns: ColumnProps<Order>[] = [
    {
      dataIndex: 'offer.provider.name',
      title: 'Provider',
      render: (_, { offer }): React.ReactNode => (
        <span className="title">{offer && offer.provider ? offer.provider.name : '---'}</span>
      ),
    },
    {
      dataIndex: 'offer.name',
      title: 'Offer',
      render: (_, { offer }): number | string => (offer ? offer.name : '---'),
    },
    {
      dataIndex: 'address',
      title: 'Address',
      render: (_, { address }): React.ReactNode => (
        <strong className="iconed-text">
          <Icon type="environment" theme="filled" />

          {getAddressFromObject(address)}
        </strong>
      ),
    },
    {
      dataIndex: 'offer.pricing.price',
      title: 'Price',
      render: (_, { offer }): number | string => (offer && offer.pricing ? offer.pricing.price : '---'),
    },
    {
      dataIndex: 'send_to_nett.status',
      title: 'Send status',
      render: (_, { send_to_nett: send }): React.ReactNode => (
        <Badge status={send.status === 200 ? 'success' : 'warning'} text={send.status} />
      ),
    },
    {
      dataIndex: 'send_to_nett.content',
      title: 'Send status text',
    },
    {
      dataIndex: 'date_created',
      title: 'Created at',
      render: (_, { date_created: date }): React.ReactNode => {
        const dateObject = new Date(date);

        return <Time noWrap date={`${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString()}`} noParse />;
      },
    },
    {
      title: 'Actions',
      width: 200,
      align: 'center',
      render: (_, { id, send_to_nett: send }): React.ReactNode => (
        <>
          <span
            className={`order-resend ${send.status === 200 ? 'unactive' : 'active'}`}
            onClick={(): Promise<void> => onResendOrder(id)}
          >
            Resend
          </span>

          <Divider type="vertical" />

          <Link route="OrderManagementPage" params={{ id }}>
            Details
          </Link>
        </>
      ),
    },
  ];

  const pagination = React.useMemo(
    () => ({
      pageSize: 15,
      total: data.total,
      current: page,
      onChange: (newPage: number): void => setPage(newPage),
    }),
    [data.pages, page],
  );

  return (
    <PrivateLayout header={<h1>Orders</h1>} loading={loading || loadingResend} className="no-padding">
      <Table rowKey="id" pagination={pagination} columns={columns} dataSource={data.data} />
    </PrivateLayout>
  );
};

export default OrdersManagementPage;
