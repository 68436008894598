import * as React from 'react';
import cookies from 'react-cookies';
import { useHistory } from 'react-router-dom';
import { Form, Icon, Input, Button, Alert } from 'antd';
import { useRequest } from 'estafette';
import { updateToken } from 'libs/http/axios';
import { auth } from 'libs/http/api/management';
import { getRoute } from 'libs/router';
import { validate } from 'libs/object';

import './_SigninPage.scss';

const SigninPage: React.FC = () => {
  const history = useHistory();
  const { request, loading, errors } = useRequest<any>();
  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  const onSubmit = async (ev: React.FormEvent<EventTarget>): Promise<void> => {
    ev.preventDefault();

    const { token } = await request(auth.signin({ username, password }));

    cookies.save('management-token', token, { path: '/' });
    updateToken();

    return history.push(getRoute('ProvidersManagementPage'));
  };

  const onChangeUsername = ({ target }: React.ChangeEvent<HTMLInputElement>): void => setUsername(target.value);
  const onChangePassword = ({ target }: React.ChangeEvent<HTMLInputElement>): void => setPassword(target.value);

  return (
    <Form onSubmit={onSubmit} className="signin-form">
      <h1>Sign in</h1>

      {errors.detail || errors.non_field_errors ? (
        <Alert message={errors.detail || errors.non_field_errors} className="mb-15" type="warning" />
      ) : null}

      <Form.Item {...validate(errors, 'username')}>
        <Input
          prefix={<Icon type="user" className="grey" />}
          placeholder="Username"
          value={username}
          onChange={onChangeUsername}
        />
      </Form.Item>

      <Form.Item {...validate(errors, 'password')}>
        <Input.Password
          prefix={<Icon type="lock" className="grey" />}
          type="password"
          placeholder="Password"
          value={password}
          onChange={onChangePassword}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="signin-form-button" loading={loading}>
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SigninPage;
