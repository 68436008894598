/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from '../../axios';

const { baseURL } = axios.defaults;

export interface ScrappersGetParams {
  page?: string | number;
}

export interface Scrapper {
  id: string;
  worker: string;
  log_type: string;
  date_created: string;
  photo?: string;
  message?: string;
  data: {
    id: number;
    address: { [key: string]: string | number };
  };
}

export interface ScrappersGetResponse {
  pages: number;
  total: number;
  data: Scrapper[];
}

export const scrappers = {
  get: (params: ScrappersGetParams) =>
    axios.get<ScrappersGetResponse>(`${baseURL}/admin/activity/scrappers`, { params }),
  read: ({ id }: { id: number | string }) => axios.get<Scrapper>(`${baseURL}/admin/activity/scrappers/${id}`),
};
