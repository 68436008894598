import axios from 'axios';
import cookies from 'react-cookies';
import env from '@beam-australia/react-env';
import { history, getRoute } from 'libs/router';

const instance = axios.create({
  baseURL: env('API_URL'),
  headers: {
    'Accept-Language': 'se',
    post: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  },
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // check if code is Unauthorised and user is on management module
      if (error.response.status === 401 && history.location.pathname.match('/management/')) {
        history.push(getRoute('SigninPage'));
      }
    }

    // eslint-disable-next-line no-undef
    return Promise.reject(error);
  },
);

export const updateToken = (): void => {
  const token = cookies.load('management-token');

  if (token) {
    instance.defaults.headers.common.Authorization = `Token ${token}`;
  } else {
    delete instance.defaults.headers.common.Authorization;
  }
};

updateToken();

export default instance;
