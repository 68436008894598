import * as React from 'react';
import moment from 'moment';
import { Icon } from 'antd';

import './_Time.scss';

interface Props {
  date?: string;
  format?: string;
  noParse?: boolean;
  noWrap?: boolean;
}

export const Time: React.FC<Props> = ({ date, format, noParse, noWrap }) => (
  <>
    {date ? (
      <span className={`time iconed-text ${noWrap ? 'no-wrap' : ''}`}>
        <Icon type="clock-circle" theme="filled" />

        {format !== 'fromNow' && <span>{!noParse ? moment(date).format(format || 'YYYY-MM-DD') : date}</span>}
        {format === 'fromNow' && <span>{moment(date).fromNow()}</span>}
      </span>
    ) : (
      '---'
    )}
  </>
);
