import * as React from 'react';
import { render } from 'react-dom';
import { ConfigProvider, Divider } from 'antd';
import svSE from 'antd/es/locale/sv_SE';
import { CreateRouter } from 'libs/router';
import * as serviceWorker from 'libs/serviceWorker';

import './styles/index.scss';

render(
  <ConfigProvider locale={svSE}>
    {/* use it to import general styles from antd to avoid problems in case when antd's components are not in component */}
    <Divider style={{ display: 'none' }} />

    <CreateRouter />
  </ConfigProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
