import * as React from 'react';
import { token } from './string';
import { WSHOST } from '../config';

export type Statuses = 'connected' | 'disconnected' | 'pending';

let socket: { [key: string]: any } = {};
let interval: NodeJS.Timeout;
let connectInterval: NodeJS.Timeout;

const socketToken = token();

export function useSocket<T>(): {
  data: T | null;
  status: Statuses;
  request: (address: { [key: string]: any }) => void;
  connect: () => void;
} {
  const [data, setData] = React.useState<T | null>(null);
  const [status, setStatus] = React.useState<Statuses>('disconnected');
  const $status = React.useRef('disconnected');
  const reconnections = React.useRef(0);

  React.useEffect(() => {
    return (): void => {
      if (socket.close) {
        socket.close();
      }

      clearInterval(interval);
    };
  }, []);

  const connect = (): void => {
    clearInterval(interval);

    setStatus('pending');
    $status.current = 'pending';

    socket = new WebSocket(`${WSHOST}/offers/${socketToken}`);

    socket.onmessage = ({ data: $data }: { data: string }): void => {
      if ($data) {
        const socketData: T = JSON.parse($data);

        setData(socketData);
      }
    };

    socket.onopen = (): void => {
      setStatus('connected');
      $status.current = 'connected';

      console.log('[SOCKET] Nettburreau Socket has been connected');
    };

    socket.onerror = (err: any): void => {
      setStatus('disconnected');
      $status.current = 'disconnected';

      console.error(err);
    };

    socket.onclose = (err: any): void => {
      console.log(`[SOCKET] Nettburreau Socket has been disconnected (${err.code || 'N/A'})`);

      setStatus('disconnected');
      $status.current = 'disconnected';

      interval = setInterval(() => {
        connect();
      }, 4000);
    };
  };

  const request = React.useCallback(
    (address: { [key: string]: string | number }): void => {
      clearInterval(connectInterval);

      if ($status.current === 'disconnected') {
        connect();
      }

      if ($status.current !== 'connected') {
        console.log('[SOCKET] Nettburreau Socket is not connected, wait ...');

        reconnections.current = reconnections.current + 1;
        connectInterval = setInterval(() => request(address), 125 * reconnections.current);
      } else {
        clearInterval(connectInterval);
        reconnections.current = 0;

        socket.send(
          JSON.stringify({
            token: socketToken,
            address,
          }),
        );
      }
    },
    [status],
  );

  return {
    data,
    status,
    request,
    connect,
  };
}
