import * as React from 'react';

import './_Count.scss';

interface Props {
  size?: 'big';
  loading: boolean;
  count?: number;
}

export const Count: React.FC<Props> = ({ size = 'default', loading, count }) => {
  return <strong>{loading ? <span className={`ra-loader-count-spin ra-spin-size-${size}`} /> : count || null}</strong>;
};

let timer: NodeJS.Timeout;
export const CountDown: React.FC<{ total: number }> = ({ total }) => {
  const [counter, setCounter] = React.useState<number>(0);

  React.useEffect(() => {
    const onTick = (): void => {
      if (typeof total === 'number') {
        clearTimeout(timer);

        if (counter < total && total - 1 > counter) {
          timer = setTimeout(() => setCounter(prevCounter => prevCounter + 1), 40);
        } else {
          setCounter(total);
        }
      }
    };

    onTick();
  }, [total, counter]);

  return <>{counter}</>;
};
