import * as React from 'react';
import { Badge } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useRequest } from 'estafette';
import { providers } from 'libs/http/api/management';
import { ProvidersGetResponse, Provider } from 'libs/http/api/management/providers';
import { Link } from 'libs/router';
import { Table } from 'ui/organisms';
import { PrivateLayout } from 'ui/templates';

const columns: ColumnProps<Provider>[] = [
  {
    dataIndex: 'name',
    title: 'Name',
    render: (_, { name }): React.ReactNode => <b>{name}</b>,
  },
  {
    dataIndex: 'active',
    title: 'Status',
    render: (_, { active }): React.ReactNode => (
      <Badge status={active ? 'success' : 'error'} text={active ? 'Active' : 'Unactive'} />
    ),
  },
  {
    title: 'Actions',
    width: 100,
    align: 'center',
    render: (_, { id }): React.ReactNode => (
      <Link route="ProviderManagementPage" params={{ update: id }}>
        Edit
      </Link>
    ),
  },
];

const ProvidersManagementPage: React.FC = () => {
  const { request, data, loading } = useRequest<ProvidersGetResponse>();
  const [page, setPage] = React.useState<number>(1);

  React.useEffect(() => {
    request(providers.get({ page }));
  }, [page]);

  const pagination = React.useMemo(
    () => ({
      pageSize: 15,
      total: data.total,
      current: page,
      onChange: (newPage: number): void => setPage(newPage),
    }),
    [data.pages, page],
  );

  return (
    <PrivateLayout header={<h1>Providers</h1>} loading={loading} className="no-padding">
      <Table rowKey="id" pagination={pagination} columns={columns} dataSource={data.data} />
    </PrivateLayout>
  );
};

export default ProvidersManagementPage;
