import * as React from 'react';
import { Arrow } from '../../resources';

import './_Select.scss';

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
  exclude?: string;
  excludeAll?: boolean;
}

interface Props {
  onChange: (value: string) => void;
  value: string;
  options: Option[];
  loading?: boolean;
}

export const Select: React.FC<Props> = ({ onChange, value, options, loading }) => {
  const onChangeHandler = ({ target }: React.ChangeEvent<HTMLSelectElement>): void => onChange(target.value);

  return (
    <div className="ra-select-wrapper">
      <div className="ra-select-arrow">
        <Arrow />
      </div>

      <select className="ra-select" value={value} onChange={onChangeHandler}>
        {options.map(({ label, value, disabled }) => (
          <option key={value} value={value} disabled={disabled || loading}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};
