import * as React from 'react';
import { formatMoney } from '../../libs/number';

import './_Price.scss';

interface Props {
  price: number;
  desc?: string;
  loading?: boolean;
  size?: 'big' | 'small' | 'default';
  className?: string;
}

export const Price: React.FC<Props> = ({ price, desc, loading, className = '', size = 'default' }) => {
  const classLoading = loading ? 'skeleton skeleton-text' : '';

  return (
    <span className={`ra-price ${className} ra-price-${size}`}>
      <span className={classLoading}>{formatMoney(price)}</span>{' '}
      <small className={classLoading}>{desc || 'kr/mån'}</small>
    </span>
  );
};
