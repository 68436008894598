import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useRequest } from 'estafette';
import { Input, Form, Icon, Switch } from 'antd';
import { nomenclatures } from 'libs/http/api/management';
import { Nomenclature } from 'libs/http/api/management/nomenclatures';
import { validate } from 'libs/object';
import { Link, getRoute } from 'libs/router';
import { Button } from 'ui/atoms';
import { PrivateLayout } from 'ui/templates';

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 6 },
};

const NomenclatureManagementPage: React.FC = () => {
  const history = useHistory();
  const { update, nomenclature } = useParams();
  const { request, errors, loading } = useRequest();
  const { request: requestNomenclature, data: dataNomenclature, loading: loadingNomenclature } = useRequest<
    Nomenclature
  >();

  const [name, setName] = React.useState<string>('');
  const [position, setPosition] = React.useState<string | number>('');
  const [checked, setChecked] = React.useState<boolean>(false);
  const [active, setActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (update && update !== 'add' && nomenclature) {
      requestNomenclature(nomenclatures.read({ id: update, nomenclature }));
    }
  }, []);

  React.useEffect(() => {
    const onMergeData = (): void => {
      setName(dataNomenclature.name);
      setPosition(dataNomenclature.position);
      setChecked(dataNomenclature.default);
      setActive(dataNomenclature.is_active);
    };

    onMergeData();
  }, [dataNomenclature]);

  const onSubmit = async (ev: React.FormEvent<EventTarget>): Promise<void> => {
    ev.preventDefault();

    if (update && nomenclature) {
      await request(
        nomenclatures.patch({
          id: update,
          nomenclature,
          position,
          default: checked,
          // eslint-disable-next-line @typescript-eslint/camelcase
          is_active: active,
        }),
      );

      history.push(getRoute('NomenclaturesManagementPage', { nomenclature }));
    }
  };

  const onChangeName = ({ target }: React.ChangeEvent<HTMLInputElement>): void => setName(target.value);
  const onChangePosition = ({ target }: React.ChangeEvent<HTMLInputElement>): void => setPosition(target.value);
  const onChangeChecked = (value: boolean): void => setChecked(value);
  const onChangeActive = (value: boolean): void => setActive(value);

  return (
    <PrivateLayout header={<h1>Nomenclature edit</h1>} loading={loadingNomenclature}>
      <Form {...formItemLayout} className="vertical-form" onSubmit={onSubmit}>
        <Form.Item label="Name" {...validate(errors, 'name')}>
          <Input value={name} onChange={onChangeName} />
        </Form.Item>

        <Form.Item label="Position" {...validate(errors, 'position')}>
          <Input value={position} onChange={onChangePosition} />
        </Form.Item>

        <Form.Item label="Default" {...validate(errors, 'default')}>
          <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            checked={checked}
            onChange={onChangeChecked}
          />
        </Form.Item>

        <Form.Item label="Active" {...validate(errors, 'is_active')}>
          <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            checked={active}
            onChange={onChangeActive}
          />
        </Form.Item>

        <Form.Item colon={false} label=" " className="form-item-button">
          <Link route="NomenclaturesManagementPage" params={{ nomenclature }}>
            <Button>Back</Button>
          </Link>

          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </PrivateLayout>
  );
};

export default NomenclatureManagementPage;
