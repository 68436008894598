import * as React from 'react';

import './_Button.scss';

interface Props {
  size?: 'large';
  type?: 'default' | 'primary';
  loading?: boolean;
  disabled?: boolean;
  htmlType?: 'button' | 'submit';
  className?: string;
  style?: { [key: string]: string | number | undefined };
  onClick?: () => void;
}

export const Button: React.FC<Props> = ({
  loading = false,
  type = 'default',
  size = 'default',
  htmlType = 'button',
  className = '',
  ...props
}) => (
  <button
    type={htmlType}
    className={`ra-button ra-button-type-${type} ra-button-size-${size} ${className}`}
    disabled={props.disabled || loading}
    onClick={props.onClick}
    style={props.style}
  >
    {loading && <div className="ra-button-spin" />}

    <span className="ra-button-text">{props.children}</span>
  </button>
);
