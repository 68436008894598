/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AxiosRequestConfig } from 'axios';
import axios from '../../axios';

const { baseURL } = axios.defaults;

export interface AuthSigninOptions {
  username: string;
  password: string;
}

export const auth = {
  signin: (options: AuthSigninOptions & AxiosRequestConfig) =>
    axios.post<{ token: string }>(`${baseURL}/admin/users/auth`, options),
};
