import { Addresses } from '../types';
import { getAddressFromObject } from './string';

export const indexedFirst = (arr: any[]): any[] => arr && arr.map((item, key) => ({ ...item, key: key + 1 }));

export function compareValues(key: string, $order?: 'desc' | 'asc') {
  return function innerSort(a: any, b: any): number {
    const order = $order || 'desc';

    // eslint-disable-next-line no-prototype-builtins
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

export function getRandom<T = any>(arr: T[], n: number): T[] {
  let len = arr.length;
  const result = new Array(n);
  const taken = new Array(len);

  if (n < len) {
    while (n--) {
      const x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
  }

  return result;
}

export const mapSuggestions = (
  suggestions: Addresses[],
): {
  key?: string;
  value: any;
  label: string;
}[] =>
  suggestions.map(suggestion => {
    const label = getAddressFromObject(suggestion);

    return { key: label, value: suggestion, label };
  });
