import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { APIHOST } from './config';
import { Offer, Provider, Addresses } from './types';
import { useSocket } from './libs/useSocket';
import { parseQuery, keys, parseObject, cleanEmptyStrings } from './libs/object';
import { compareValues } from './libs/array';
import { SearchTemplate, ResultsTemplate } from './templates';

// cases
// import { emptyCase } from './__tests__/empty_data_case_3';

interface Data {
  offers?: Offer[];
  data?: Offer[];
  workers?: number;
  worker?: string;
  type: 'response' | 'update';
  address_id?: number;
  additional?: { date_parsed?: string };
}

interface NomenclatureOptions {
  [key: string]: string;
}

interface NomenclatureDefaultOptions {
  [key: string]: { label: string; disabled?: boolean; position?: number };
}

export interface Nomenclatures {
  speeds: NomenclatureOptions;
  broadbands: NomenclatureDefaultOptions;
  types: NomenclatureDefaultOptions;
  sorts: NomenclatureDefaultOptions;
  [key: string]: NomenclatureDefaultOptions | NomenclatureOptions;
}

export interface State {
  defined: boolean;
  speeds: number | string;
  broadbands: string[];
  types: string[];
  sorts: string;
}

export interface Providers {
  [key: string]: Provider;
}

const filterByMinimumPrice = (data: Offer[], min?: number): Offer[] =>
  data.filter(({ speed, broadband_type: type }) =>
    type.codename !== 'mobilnatet' ? speed.value >= (min || 10) : true,
  );

const initialFilters = {
  defined: false,
  speeds: '100',
  broadbands: [],
  types: [],
  sorts: 'price',
};

const initialNomenclatures = {
  broadbands: {
    fastBredband: { label: 'Fast bredband', exclude: 'mobiltBredband', disabled: true },
    mobiltBredband: { label: 'Mobilt bredband', exclude: 'fastBredband', disabled: true },
  } as NomenclatureDefaultOptions,
  speeds: {} as NomenclatureOptions,
  types: {
    bindingTime: { label: 'Utan bindningstid', disabled: true },
    router: { label: 'Router ingår', disabled: true },
  } as NomenclatureDefaultOptions,
  sorts: {
    price: { label: 'Pris' },
    order: { label: 'Leverantör' },
    speed: { label: 'Hastighet' },
  },
};

const defaultNomenclatures = {
  broadbands: ['fastBredband'],
  types: [],
};

const filterReducer = (state: State, action: { type: string; value?: any }): State => {
  switch (action.type) {
    case 'SET_SPEEDS': {
      return { ...state, speeds: action.value };
    }
    case 'SET_BROADBANDS': {
      return { ...state, broadbands: action.value };
    }
    case 'SET_TYPES': {
      return { ...state, types: action.value };
    }
    case 'SET_SORTS': {
      return { ...state, sorts: action.value };
    }
    case 'SET_DEFAULTS': {
      return { ...state, ...action.value, defined: true };
    }
    case 'RESET': {
      return { ...initialFilters };
    }
    default:
      return state;
  }
};

let timer: NodeJS.Timeout;
let filterLoadingTimer: NodeJS.Timeout;
let loadingTimer: NodeJS.Timeout;
let workersTimer: NodeJS.Timeout;
const ResultsApp: React.FC = () => {
  const { data: socketData, status, request } = useSocket<Data>();
  const [step, setStep] = React.useState<number>(1);
  const [pureData, setPureData] = React.useState<Offer[]>([]);
  const [updatedData, setUpdatedData] = React.useState<Offer[]>([]);
  const [data, setData] = React.useState<Offer[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [filterLoading, setFilterLoading] = React.useState<boolean>(true);
  const [notFound, setNotFound] = React.useState<boolean | string>(false);
  const [showSearch, setShowSearch] = React.useState<boolean>(false);
  const [addressChanged, setAddressChanged] = React.useState<boolean>(false);
  const [address, setAddress] = React.useState<Addresses>({} as Addresses);
  const [addressId, setAddressId] = React.useState<number | null>(null);
  const [workers, setWorkers] = React.useState<number | null>(null);
  const [workersProvider, setWorkersProviders] = React.useState<string[]>([]);
  const [fallback, setFallback] = React.useState<boolean>(false);
  const [providers, setProviders] = React.useState<Providers>({} as Providers);
  const [nomenclatures, setNomenclatures] = React.useState<Nomenclatures>(
    JSON.parse(JSON.stringify(initialNomenclatures)),
  );
  const [filtered, setFiltered] = React.useState<boolean>(false);
  const [parsedDate, setParsedDate] = React.useState<string>('');
  const [filter, dispatch] = React.useReducer(filterReducer, { ...initialFilters });

  React.useEffect(() => {
    const onFetchProviders = async (): Promise<void> => {
      const data: Provider[] = await fetch(`${APIHOST}/api/v1/providers`).then(res => res.json());

      const $providers: Providers = {};
      data.map(item => ($providers[item.codename] = item));

      setProviders($providers);
    };

    const onCheckDefaultAddress = (): void => {
      const query = parseQuery<Addresses>(window.location.search);
      const addressKeys = ['street', 'street_number', 'postal_code', 'locality', 'county', 'municipality'];

      if (Object.keys(query).some((r: string) => addressKeys.indexOf(r) >= 0)) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onSubmitAddress(query);
      }
    };

    onFetchProviders();
    onCheckDefaultAddress();

    return (): void => {
      clearTimeout(timer);
      clearTimeout(filterLoadingTimer);
      clearTimeout(loadingTimer);
      clearTimeout(workersTimer);
    };
  }, []);

  React.useEffect(() => {
    const onChangeParentClassName = (): void => {
      const parent = document.getElementById('results-app-parent');

      if (parent) {
        // there're 2 steps
        parent.classList.remove(`results-app-step-${step === 1 ? 2 : 1}`);
        parent.classList.add(`results-app-step-${step}`);
      }
    };

    onChangeParentClassName();
  }, [step]);

  React.useEffect(() => {
    const onSetAddressBySearch = (): void => {
      setAddressChanged(false);
      if (keys<Addresses>(address).length > 0) {
        request(cleanEmptyStrings(address));
      }
    };

    onSetAddressBySearch();
  }, [address]);

  React.useEffect(() => {
    const onSaveSocketData = (): void => {
      if (socketData) {
        const { data: $data = [], type, workers: $workers, worker, additional } = socketData;

        if (type === 'response') {
          setAddressChanged(true);

          setPureData(
            filterByMinimumPrice(
              $data.reduce(
                (prev: any, current: any) => prev.concat(prev.find((y: any) => y.id === current.id) ? [] : [current]),
                [],
              ),
            ),
          );

          setWorkers(0);
        }

        if (additional && additional.date_parsed) {
          const date = new Date(additional.date_parsed).toLocaleTimeString([], {
            minute: '2-digit',
            hour: '2-digit',
            hour12: false,
          });

          setParsedDate(date !== 'Invalid Date' ? `${date}` : 'okänd');
        }

        if (socketData.address_id) {
          setAddressId(socketData.address_id);
        }

        if (type === 'update') {
          setUpdatedData(filterByMinimumPrice($data));
        }

        if ($workers !== undefined) {
          setWorkers($workers);
        }

        if (worker !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          onAddWorker(worker);
        }
      }
    };

    onSaveSocketData();
  }, [socketData]);

  React.useEffect(() => {
    const onMergeData = (): void => {
      if (updatedData.length > 0) {
        const pureDataIds = pureData.map(({ id }) => id);

        setPureData([...pureData, ...updatedData.filter(({ id }) => !pureDataIds.includes(id))]);

        setAddressChanged(true);

        setUpdatedData([]);
      }
    };

    onMergeData();
  }, [pureData, updatedData]);

  React.useEffect(() => {
    const onCheckWorkers = (): void => {
      if (workers !== null) {
        if (workers > 0) {
          console.log('[RESULTS-APP] Nettburreau filter started timer for workers');

          clearTimeout(workersTimer);

          // wait for workers
          workersTimer = setTimeout(() => {
            console.log('[RESULTS-APP] Nettburreau filter stopped timer for workers');

            // setWorkersProviders([]);
            setWorkers(0);
          }, 30000);
        }
      }
    };

    onCheckWorkers();
  }, [pureData, workers]);

  React.useEffect(() => {
    const onParseNomenclatures = async (): Promise<void> => {
      if (!loading) {
        const $nomenclatures: Nomenclatures = JSON.parse(JSON.stringify(initialNomenclatures));

        pureData.forEach(({ binding_time: bindingTime = 0, ...item }) => {
          if (item.broadband_type.codename !== 'mobilnatet') {
            $nomenclatures.speeds[item.speed.value] = item.speed.name;

            if ($nomenclatures.broadbands.fastBredband.disabled) {
              $nomenclatures.broadbands.fastBredband.disabled = false;
            }
          }

          if (item.broadband_type.codename === 'mobilnatet' && $nomenclatures.broadbands.mobiltBredband.disabled) {
            $nomenclatures.broadbands.mobiltBredband.disabled = false;
          }

          if ($nomenclatures.types.bindingTime.disabled && bindingTime === 0) {
            $nomenclatures.types.bindingTime.disabled = false;
          }

          if (item.has_router) {
            $nomenclatures.types.router.disabled = false;
          }
        });

        setNomenclatures($nomenclatures);
      }
    };

    onParseNomenclatures();
  }, [pureData, loading]);

  React.useEffect(() => {
    const onSetDefaultNomenclatures = (): void => {
      if (!filtered) {
        const defaults: { [key: string]: string[] } = { ...defaultNomenclatures };

        Object.keys(nomenclatures).forEach(key => {
          if (key !== 'speeds' && defaults[key]) {
            Object.keys(nomenclatures[key]).forEach(childKey => {
              const target = nomenclatures[key][childKey];

              if (typeof target !== 'string' && target.disabled) {
                defaults[key] = defaults[key].filter(item => item !== childKey);
              }
            });
          }
        });

        const speeds = Object.keys(nomenclatures.speeds).includes('100') ? '100' : '10';

        dispatch({ type: 'SET_DEFAULTS', value: { ...defaults, speeds } });
      }
    };

    onSetDefaultNomenclatures();
  }, [defaultNomenclatures, nomenclatures, filtered]);

  React.useEffect(() => {
    const onFilterData = (): void => {
      if (step === 2 && addressChanged) {
        clearTimeout(filterLoadingTimer);

        let cloneData = [...pureData];

        if (filter.defined && cloneData.length > 0) {
          if (filter.broadbands.length > 0) {
            if (filter.broadbands.includes('fastBredband')) {
              cloneData = cloneData.filter(({ broadband_type: broadband }) => broadband.codename !== 'mobilnatet');
            }

            if (filter.broadbands.includes('mobiltBredband')) {
              cloneData = cloneData.filter(({ broadband_type: broadband }) => broadband.codename === 'mobilnatet');
            }
          }

          if (!filter.broadbands.includes('mobiltBredband') && filter.speeds) {
            const speeds = Number(filter.speeds);

            cloneData = cloneData.filter(({ speed }) => speed.value >= speeds);
          }

          if (filter.types.includes('bindingTime')) {
            cloneData = cloneData.filter(({ binding_time: bindingTime }) => bindingTime === 0);
          }

          if (filter.types.length > 0) {
            if (filter.types.includes('router')) {
              cloneData = cloneData.filter(({ has_router: hasRoute }) => hasRoute);
            }
          }

          if (filter.sorts === 'price') {
            cloneData = cloneData
              .map(item => ({ ...item, price: item.pricing.price_12_months.per_month }))
              .sort(compareValues('price', 'asc'));
          }

          if (filter.sorts === 'order') {
            cloneData = cloneData.sort((a, b) =>
              a.provider.name !== b.provider.name ? (a.provider.name < b.provider.name ? -1 : 1) : 0,
            );
          }

          if (filter.sorts === 'speed') {
            cloneData = cloneData
              .map(item => ({ ...item, $speed: item.speed.value }))
              .sort(compareValues('$speed', 'asc'));
          }

          setData(cloneData);
        }

        setFilterLoading(false);
      }
    };

    onFilterData();
  }, [step, addressChanged, pureData, filter]);

  React.useEffect(() => {
    const onCheckFilteredData = (): void => {
      if (step === 2) {
        if (workers && !filtered && pureData.length > 0) {
          const hasFastBroadbandAndEnoughSpeed =
            pureData.filter(
              ({ broadband_type: broadband, speed }) => broadband.codename !== 'mobilnatet' && speed.value >= 100,
            ).length > 0;

          if (!hasFastBroadbandAndEnoughSpeed) {
            console.log("[RESULTS-APP] Nettburreau filter hasn't fast broadband or enough speed and wait for them.");
          }

          if (hasFastBroadbandAndEnoughSpeed && workers > 0) {
            loadingTimer = setTimeout(() => setLoading(false), 1000);
          }
        }
      }
    };

    onCheckFilteredData();
  }, [step, pureData, filtered, workers, filter]);

  React.useEffect(() => {
    const onCheckNotFoundData = (): void => {
      if (workers === 0) {
        if (pureData.length === 0 || (pureData.length > 0 && data.length === 0)) {
          setNotFound(filtered ? 'Vi hittar inga bredband som matchar dina filtreringar' : true);
          setLoading(false);
        } else {
          setNotFound(false);

          clearTimeout(loadingTimer);
          loadingTimer = setTimeout(() => setLoading(false), 1000);

          clearTimeout(filterLoadingTimer);
          filterLoadingTimer = setTimeout(() => setFilterLoading(false), 150);
        }
      }
    };

    onCheckNotFoundData();
  }, [filtered, pureData, data, workers]);

  React.useEffect(() => {
    const onCheckFallback = (): void => {
      if (!filtered && workers === 0 && !loading && data.length > 0) {
        setFallback(filter.broadbands.length === 0);
      }
    };

    onCheckFallback();
  }, [filter, filtered, loading, data, workers]);

  React.useEffect(() => {
    const onStartFilterLoading = (): void => setFilterLoading(true);

    onStartFilterLoading();
  }, [filter]);

  const onSubmitAddress = (newAddress: Addresses): void => {
    setStep(2);

    // reset to initial state
    setAddressId(null);
    dispatch({ type: 'RESET' });
    setParsedDate('');
    setAddressChanged(false);
    setShowSearch(false);
    setPureData([]);
    setData([]);
    setFilterLoading(true);
    setLoading(true);
    setNotFound(false);
    setFiltered(false);
    setFallback(false);
    setWorkersProviders([]);
    setWorkers(null);

    window.history.pushState('', '', parseObject(newAddress));

    setAddress(newAddress);
  };

  const onChangeFilter = React.useCallback(
    (type: 'speeds' | 'broadbands' | 'types' | 'sorts', value: any, notFiltered?: boolean) => {
      if (!notFiltered) {
        setFallback(false);
        setFiltered(true);
      }

      dispatch({ type: `SET_${type.toUpperCase()}`, value });
    },
    [dispatch],
  );

  const onAddWorker = (worker: string): void => {
    if (!workersProvider.includes(worker)) {
      const $workersProvider = [...workersProvider];

      $workersProvider.unshift(worker);

      setWorkersProviders($workersProvider);
    }
  };

  const onShowSearch = (): void => setShowSearch(true);

  const onCloseSearch = (): void => setShowSearch(false);

  const speeds = React.useMemo(() => Object.keys(nomenclatures.speeds), [nomenclatures]);

  const hasMobileFilter = React.useMemo(() => filter.broadbands.includes('mobiltBredband'), [filter.broadbands]);

  if (step === 1) {
    return <SearchTemplate defaultAddress={address} />;
  }

  if (step === 2) {
    return (
      <ResultsTemplate
        parsedDate={parsedDate}
        socketStatus={status}
        workers={workers}
        workersProvider={workersProvider}
        providers={providers}
        fallback={fallback}
        filterLoading={!workers && filterLoading}
        loading={loading}
        pureData={pureData}
        data={data}
        address={address}
        addressId={addressId || 0}
        showSearch={showSearch}
        onCloseSearch={onCloseSearch}
        onShowSearch={onShowSearch}
        filter={filter}
        speeds={speeds}
        notFound={notFound}
        onChangeFilter={onChangeFilter}
        nomenclatures={nomenclatures}
        onSubmit={onSubmitAddress}
        hasMobileFilter={hasMobileFilter}
      />
    );
  }

  return null;
};

const interval = setInterval(() => {
  if (document.getElementById('results-app')) {
    clearInterval(interval);

    ReactDOM.render(<ResultsApp />, document.getElementById('results-app'));
  }
}, 100);
