import * as React from 'react';
import { Input, InputNumber, Checkbox, DatePicker, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Field as FieldInterface } from 'libs/http/api/management/field';

interface Props {
  item: FieldInterface;
  placeholder?: string;
  onChange: (codename: string, value: any) => void;
}

export const Field: React.FC<Props> = ({ item, onChange, placeholder }) => {
  // I'm not sure if it's necessary
  // placeholder={`Ange ${item.name.toLowerCase()}`}

  const onChangeChoice = (value: any[]): void => onChange(item.codename, value);
  const onChangeDate = (_: any, value: any): void => onChange(item.codename, value);
  const onChangeCheckbox = ({ target }: CheckboxChangeEvent): void => onChange(item.codename, target.checked);
  const onChangeInteger = (value: number | undefined): void => onChange(item.codename, value);
  const onChangeInput = ({ target }: React.ChangeEvent<HTMLInputElement>): void =>
    onChange(item.codename, target.value);
  const onChangeChar = ({ target }: React.ChangeEvent<HTMLTextAreaElement>): void =>
    onChange(item.codename, target.value);

  if (item.type === 'boolean') {
    return (
      <Checkbox className="checkbox-small mt-10" onChange={onChangeCheckbox}>
        {item.props.help_text}
      </Checkbox>
    );
  }

  if (item.type === 'integer' || item.type === 'float') {
    return (
      <InputNumber
        placeholder={placeholder}
        {...(item.props.min !== undefined && { min: item.props.min })}
        {...(item.props.max !== undefined && { max: item.props.max })}
        onChange={onChangeInteger}
      />
    );
  }

  if (item.type === 'email') {
    return <Input allowClear placeholder={placeholder} onChange={onChangeInput} />;
  }

  if (item.type === 'char') {
    return !item.props.multiline ? (
      <Input onChange={onChangeInput} placeholder={placeholder} />
    ) : (
      <Input.TextArea allowClear placeholder={placeholder} onChange={onChangeChar} />
    );
  }

  if (item.type === 'date') {
    return <DatePicker placeholder={placeholder} onChange={onChangeDate} format="YYYY-MM-DD" />;
  }

  if (item.type === 'date_time') {
    return <DatePicker showTime placeholder={placeholder} onChange={onChangeDate} />;
  }

  if (item.type === 'choice') {
    return (
      <Select mode="multiple" placeholder={placeholder} onChange={onChangeChoice}>
        {item.props.choices.map((choice: string) => (
          <Select.Option value={choice} key={choice}>
            {choice}
          </Select.Option>
        ))}
      </Select>
    );
  }

  if (item.type === 'read_only') {
    return <div className="checkout-read-only">{item.props.default}</div>;
  }

  return null;
};
