/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from '../../axios';

const { baseURL } = axios.defaults;

export interface NomenclaturesGetParams {
  id?: string | number;
  nomenclature: string;
}

export interface Nomenclature {
  id: number;
  name: string;
  codename: string;
  value: string;
  position: number;
  default: boolean;
  is_active: boolean;
}

export interface NomenclaturesPatchData {
  id: number | string;
  nomenclature: string;
  name?: string;
  codename?: string;
  value?: string;
  position?: number | string;
  default?: boolean;
  is_active?: boolean;
}

export const nomenclatures = {
  get: ({ nomenclature, ...params }: NomenclaturesGetParams) =>
    axios.get<Nomenclature[]>(`${baseURL}/admin/nomenclatures/${nomenclature}`, { params }),
  read: ({ id, nomenclature }: NomenclaturesGetParams) =>
    axios.get<Nomenclature>(`${baseURL}/admin/nomenclatures/${nomenclature}/${id}`),
  patch: ({ id, nomenclature, ...data }: NomenclaturesPatchData) =>
    axios.patch(`${baseURL}/admin/nomenclatures/${nomenclature}/${id}`, data),
};
