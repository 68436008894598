import * as React from 'react';
import { Row, Col, Form } from 'antd';
import { has } from 'libs/object';
import { getAddressFromObject } from 'apps/results-app/libs/string';
import { Price } from 'apps/results-app/atoms';
import { Time } from 'ui/atoms';

interface Props {
  // FIXME
  data: any;
  speed?: string;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export const Offer: React.FC<Props> = ({ data, speed }) => (
  <Row type="flex">
    <Col span={12}>
      <Form {...formItemLayout} className="vertical-form">
        {data.date_created && (
          <Form.Item label="Created at">
            <Time date={data.date_created} />
          </Form.Item>
        )}

        {speed && <Form.Item label="Nedladdning">{speed}</Form.Item>}

        <Form.Item label="Uppladdning">{data.speed_up ? `${data.speed_up} Mbit/s` : '---'}</Form.Item>

        <Form.Item label="Bindningstid">{data.binding_time ? `${data.binding_time} mån` : '---'}</Form.Item>

        <Form.Item label="Uppsägningstid">{data.notice_time ? `${data.notice_time} mån` : '---'}</Form.Item>

        <Form.Item label="Ingår router?">{data.has_router ? 'Ja' : 'Nej'}</Form.Item>

        <Form.Item label="Datamängd">
          {data.data_amount === null || data.data_amount === 0 ? 'Obegränsad' : `${data.data_amount || 'N/A'} GB`}
        </Form.Item>
      </Form>
    </Col>

    <Col span={12}>
      <Form {...formItemLayout} className="vertical-form">
        <Form.Item label="Adress">
          <strong>{getAddressFromObject(data.address)}</strong>
        </Form.Item>

        <Form.Item label="Nätverk">{has<string>(data, 'network.name', '---')}</Form.Item>

        <Form.Item label="Typ">{has<string>(data, 'broadband_type.name', '---')}</Form.Item>

        <Form.Item label="Kostnad">
          <Price
            price={has<number>(data, 'pricing.price_12_months.per_month', 0)}
            desc={`${has<number>(data, 'pricing.price_x_months.per_month', 0) ||
              has<number>(data, 'pricing.per_month', 0)} kr/mån i ${has<number>(
              data,
              'pricing.price_x_months.months',
              12,
            )} månader`}
          />
        </Form.Item>

        <Form.Item label="Därefter">
          <Price price={has<number>(data, 'pricing.price', 0)} desc="kr/mån" />
        </Form.Item>

        <Form.Item label="Totalt">
          <Price price={has<number>(data, 'pricing.price_12_months.total', 0)} desc="kr/år" />
        </Form.Item>
      </Form>
    </Col>
  </Row>
);
