/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from '../../axios';
import { Field } from './field';
import { indexed } from 'libs/array';

const { baseURL } = axios.defaults;

export interface ProvidersGetParams {
  page?: string | number;
}

export interface Provider {
  id: number;
  fields: Field[];
  name: string;
  codename: string;
  logo: string;
  terms: string;
  integrity: string;
  description: string;
  price_description: string;
  order_color?: string;
  order_url: string;
  prefix_order_url: string;
  order_url_color: string;
  on_page_order: boolean;
  hide_order_button: boolean;
  scrapper_active: boolean;
  active: boolean;
}

export interface ProvidersGetResponse {
  pages: number;
  total: number;
  data: Provider[];
}

export interface ProvidersPutData {
  name: string;
  terms: string;
  integrity: string;
  description: string;
  price_description: string;
  order_color?: string;
  order_url?: string;
  order_url_color?: string;
  on_page_order: boolean;
  scrapper_active: boolean;
  active: boolean;
}

export const providers = {
  get: (params: ProvidersGetParams) => axios.get<ProvidersGetResponse>(`${baseURL}/admin/providers`, { params }),
  put: (id: number | string, data: ProvidersPutData) => axios.put(`${baseURL}/admin/providers/${id}`, data),
  read: ({ id }: { id: number | string }) =>
    axios
      .get<Provider>(`${baseURL}/admin/providers/${id}`)
      .then(res => ({ ...res, data: { ...res.data, fields: indexed(res.data.fields) } })),
};
