export const token = (): string => {
  const rand = (): string =>
    Math.random()
      .toString(36)
      .substr(2); // remove `0.`

  return `${rand()}${rand()}${rand()}`;
};

export function getAddressFromObject(obj: { [key: string]: any }, excludedKeys?: string[]): string {
  if (typeof obj === 'object') {
    const cloneObj = { ...obj };

    if (cloneObj.postal_code && cloneObj.locality) {
      cloneObj.locality = `${cloneObj.postal_code} ${cloneObj.locality}`;
      delete cloneObj.postal_code;
    }

    if (cloneObj.street_number && cloneObj.extra_number) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      cloneObj.street_number = `${cloneObj.street_number}-${cloneObj.extra_number}`;
      delete cloneObj.extra_number;
    }

    if (cloneObj.street_number && cloneObj.letter) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      cloneObj.street_number = `${cloneObj.street_number} ${cloneObj.letter}`;
      delete cloneObj.letter;
    }

    const $excludedKeys = excludedKeys || [];

    return Object.keys(cloneObj)
      .filter(
        key =>
          ![
            ...$excludedKeys,
            'key',
            'id',
            'last_search_date',
            'date_created',
            'county',
            'country',
            'municipality',
            'origin',
          ].includes(key),
      )
      .map(key => key in cloneObj && cloneObj[key])
      .filter(i => i && i !== 'null' && i !== 'undefined')
      .join(', ');
  }

  return '';
}
